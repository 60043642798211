import { Component } from '@angular/core';

@Component({
    selector: 'l-user-create-password-error',
    template: `
        <div class="flex flex-column justify-content-center align-items-center">
            <img
                src="assets/images/create-password-error.svg"
                alt="Illustrator error create password"
            />
            <h2 class="text-center font-bold" style="margin: 20px 0 12px; font-size: 24px">
                Ouch! Page isn’t working right now
            </h2>
            <p class="text-center mt-2">
                This page does not exist. The token you requested might be
                invalid.
            </p>
        </div>
    `,
})
export class UserCreatePasswordErrorCompoennt {}
