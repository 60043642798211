<div class="layout-menubar" id="layout-menubar">
    <div>
        <div class="overflow-parent">
            <p-menubar
                styleClass="scalein"
                [model]="items"
                (click)="onClickMenu()"
            >
            </p-menubar>
        </div>
    </div>
</div>
