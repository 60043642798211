<div class="flex" style="gap: 12px">
    <div class="p-inputgroup p-inputgroup--right w-full">
        <input
            class="input-password"
            placeholder="{{ 'enter' | translate }} {{ 'password' | translate }}"
            autocomplete="new-password"
            pInputText
            [id]="id"
            [type]="showPassword ? 'text' : 'password'"
            [value]="value"
            (blur)="markAsTouched()"
            (input)="handleChange($event)"
            (change)="handleChange($event)"
        />
        <span class="p-inputgroup-addon">
            <ng-container *ngIf="value">
                <i
                    class="fa-solid fa-eye"
                    *ngIf="showPassword"
                    (click)="showPassword = !showPassword"
                ></i>
                <i
                    class="fa-solid fa-eye-slash"
                    *ngIf="!showPassword"
                    (click)="showPassword = !showPassword"
                ></i>
            </ng-container>
        </span>
    </div>

    <button
        icon="fa-solid fa-gear"
        *ngIf="showGenerator"
        pButton
        pRipple
        (click)="generateRandomPassword()"
    ></button>
</div>

<ng-container *ngIf="value && showInfo">
    <small class="block mt-1 font bold" style="color: #9f9f9f">
        {{ info }}
    </small>
    <small class="block mt-1" *ngFor="let tip of tips" style="color: #9f9f9f">{{
        tip
    }}</small>
</ng-container>
