import { WebSocketService } from './../../core/service/web-socket.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/core/service/api.service';
import { MessageBoxService } from 'src/app/core/service/message-box.service';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { ConfigService } from 'src/app/core/service/app.config.service';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { ActivityMasterService } from '@organization/activity-organization-master/service/activity-master.service';
import { AppConstant } from '@config/app.config';
import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { AppService } from 'src/app/app.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

import { NotificationsService } from 'src/app/util/notifications/service/notifications.service';
import { HelperService } from '@core/service/helper-service';
import { ProcessQueueMonitoringService } from 'src/app/util/process-queue-monitoring/service/process-queue-monitoring.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [
        `
            :host ::ng-deep {
                .p-tabview .p-tabview-nav li {
                    width: 100%;
                }

                .p-tabview .p-tabview-panels {
                    padding: 4px 0;
                }

                .notif__img-avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f5f6fa;

                    i {
                        color: #0e8ec5;
                        font-size: 20px;
                    }
                }

                .announce__img-avatar {
                    width: 8rem;
                    height: 4rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 4px;
                    }
                }

                .list-item__notification {
                    padding: 0.8rem 0.95rem;
                    cursor: pointer;

                    &:hover {
                        filter: brightness(97%);
                    }

                    &.unread {
                        position: relative;
                        background-color: #e8f2ff;
                        transition: all 0.2s;

                        &:hover {
                            filter: brightness(97%);
                        }
                    }
                }

                .confirm-dialog__flash-banner__mask {
                    z-index: 100002 !important;
                }

                .pop-up__flash-banner__mask {
                    z-index: 100001 !important;
                }

                .announcement__dialog__mask {
                    z-index: 100000 !important;
                }

                .btn__close-announcement-popup {
                    border-radius: 50%;
                    background: transparent !important;
                    color: #9f9f9f !important;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    width: 2rem !important;
                    height: 2rem !important;
                    padding: 0 !important;

                    &:hover {
                        color: #343a40;
                        border-color: transparent;
                        background-color: #e9ecef !important;
                    }
                }
            }
        `,
    ],
    animations: [
        trigger('customDialogAnnouncementAnimations', [
            transition(':enter', [
                group([
                    query(
                        '.announcement__dialog',
                        style({ transform: 'translateX(100rem)', opacity: 0 })
                    ),
                    query(
                        '.announcement__dialog',
                        animate(
                            '0.2s ease-in',
                            style({ transform: 'translateX(0)', opacity: 1 })
                        )
                    ),
                ]),
            ]),
            transition(':leave', [
                group([
                    query(
                        '.announcement__dialog',
                        style({ transform: 'translateX(0)', opacity: 1 })
                    ),
                    query(
                        '.announcement__dialog',
                        animate(
                            '0.2s ease-out',
                            style({
                                transform: 'translateX(100rem)',
                                opacity: 0,
                            })
                        )
                    ),
                ]),
            ]),
        ]),
    ],
})
export class AppTopBarComponent implements OnInit, OnDestroy {
    private readonly _web: string | undefined = localStorage.getItem('web');

    public notifTotalUnread: string = '';
    public notifListBel: any[] = [];
    public announceListBel: any[] = [];
    public announceList: any[] = [];
    public flashBannerList: any[] = [];
    public disabledRedirectNotif: boolean = false;
    public isShowConfirm: boolean = false;
    public checkedDontShowAgain: boolean = false;

    public pageFlashBanner: number = 0;

    public visibleAnn: boolean = false;
    public visibleFlash: boolean = false;

    public totalProcessMonitorings: number | undefined = 0;
    public listProcessMonitorings: any[] | undefined = [];

    selectedItem: any;

    suggestions: any[] | undefined;

    appInfo!:
        | {
              current: string | undefined;
              hasEssAccess: boolean;
              hasMssAccess: boolean;
              hasAdminAccess: boolean;
          }
        | undefined;

    items: MenuItem[];
    session;
    cities = [
        { name: 'PT. Kramayudha Ratu Motor', code: 'NY' },
        { name: 'PT. Lawencon International', code: 'RM' },
    ];
    language = [
        { label: 'EN', value: 'en' },
        { label: 'ID', value: 'id' },
    ];
    selectedLang = localStorage.getItem('locale') || 'en';
    companyId;
    e;
    onHideFlash = {
        show: () => {
            this.isShowConfirm = true;
        },
        accept: async () => {
            if (this.checkedDontShowAgain) {
                let flashBanner = this.flashBannerList[this.pageFlashBanner];
                let body = {
                    announcementId: flashBanner.id,
                    employeeId: this.session?.id,
                };
                await lastValueFrom(this.notifSrv.announceException(body));
            }
            localStorage.setItem('displayFlashAdmin', 'false');
            this.visibleFlash = false;
            this.isShowConfirm = false;
        },
        reject: () => {
            this.isShowConfirm = false;
        },
    };

    public get isSuperAdmin(): boolean {
        return this.session?.roleType === 'SUPERADMIN';
    }

    private isNotificationHandled: boolean = false;

    public loadNotif!: boolean | undefined;
    public loadAnnounce!: boolean | undefined;

    public fakeArrayEmptyState: any[] = Array.from({ length: 4 });

    constructor(
        public appMain: AppMainComponent,
        public api: ApiService,
        public msg: MessageBoxService,
        private auth: AuthenticationService,
        private route: Router,
        private config: ConfigService,
        public translate: TranslateService,
        private srv: ActivityMasterService,
        private readonly notifSrv: NotificationsService,
        private readonly _router: Router,
        private readonly _appService: AppService,
        private readonly _webSocketService: WebSocketService,
        private readonly _cd: ChangeDetectorRef,
        private readonly _confirmationService: ConfirmationService,
        public readonly _helperSrv: HelperService,
        private readonly _processQueueService: ProcessQueueMonitoringService
    ) {
        this.session = this.auth.getLocalStorage(AppConstant.userSession);
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                appMain.hideNotifications(true);
                appMain.hideTopMenu(true);
            }
        });
    }

    ngOnInit(): void {
        this.loadData();
        this._connect();

        let web: string | undefined = localStorage.getItem('web');

        if (web === 'dashboard') {
            web = 'admin';
        }

        this.appInfo = {
            current: web,
            hasEssAccess: this._appService.hasEssAccess$.value,
            hasMssAccess: this._appService.hasMssAccess$.value,
            hasAdminAccess: this._appService.hasAdminAccess$.value,
        };

        // this.getDataCompany();

        this.defaultLangChecker();
    }

    _connect() {
        if (this.auth.isLoggedIn()) {
            const tenantId = this.auth.getLocalStorage(AppConstant.tenantId);
            const userId = this.session?.id || this.session?.userId

            this._webSocketService.connect(this._web).subscribe({
                next: (connected) => {
                    if (connected) {
                        this._webSocketService
                            .subscribe(
                                `/topic/personal/${userId}`
                            )
                            .subscribe((message) => {
                                const splitText =
                                    message.headers.destination.split('/');
                                const uuid = splitText[splitText.length - 1];

                                if (uuid === userId) {
                                    if (message.body) {
                                        let resp = JSON.parse(message.body);
                                        this._notifBell();
                                        this._notifTotalUnread();
                                    }
                                }
                            });
                        this._webSocketService
                            .subscribe(`/topic/announcement/tenant/${tenantId}`)
                            .subscribe((message) => {
                                if (message.body) {
                                    let resp = JSON.parse(message.body);
                                    if (
                                        resp.lovTypeAnnouncementCode === 'ANNCH'
                                    ) {
                                        this._announcementShow(true);
                                    } else {
                                        this._flashBannerShow(true);
                                    }
                                    this._announceBell();
                                }
                            });
                        this._webSocketService
                            .subscribe(
                                `/topic/announcement/user/${userId}`
                            )
                            .subscribe((message) => {
                                const splitText =
                                    message.headers.destination.split('/');
                                const uuid = splitText[splitText.length - 1];

                                if (uuid === userId) {
                                    if (message.body) {
                                        let resp = JSON.parse(message.body);
                                        if (
                                            resp.lovTypeAnnouncementCode ===
                                            'ANNCH'
                                        ) {
                                            this._announcementShow(true);
                                        } else {
                                            this._flashBannerShow(true);
                                        }
                                        this._announceBell();
                                    }
                                }
                            });
                        this._webSocketService
                            .subscribe(
                                `/topic/monitoring-process/${this.session?.userId}`
                            )
                            .subscribe((res) => {
                                const data = JSON.parse(res.body);
                                const datas = [...this.listProcessMonitorings];

                                const index = datas.findIndex(
                                    (item) => item.processId === data.processId
                                );

                                if (index !== -1) {
                                    datas[index] = data;
                                } else {
                                    datas.push(data);
                                }

                                const parseTimeString = (
                                    timeString: string
                                ) => {
                                    const [datePart, timePart] =
                                        timeString.split(' at ');
                                    return new Date(`${datePart} ${timePart}`);
                                };

                                const getQueueNoNumber = (queueNo: string) => {
                                    const parts = queueNo.split('-');
                                    return parts.length > 1
                                        ? parseInt(parts[1], 10)
                                        : 0;
                                };

                                datas.sort((a, b) => {
                                    const dateA = parseTimeString(a.time);
                                    const dateB = parseTimeString(b.time);

                                    const timeComparison =
                                        dateB.getTime() - dateA.getTime();
                                    if (timeComparison !== 0)
                                        return timeComparison;

                                    const queueNoA = getQueueNoNumber(
                                        a.queueNo
                                    );
                                    const queueNoB = getQueueNoNumber(
                                        b.queueNo
                                    );

                                    return queueNoB - queueNoA;
                                });

                                this.listProcessMonitorings = datas;

                                this._processQueueMonitoring();
                            });
                    }
                },
                error: () => {
                    this._webSocketService.disconnect();
                },
            });
        }
        this._cd.detectChanges();
    }

    loadData() {
        this._notifTotalUnread();

        this._announcementShow();

        this._flashBannerShow();

        this._processQueueMonitoring();
    }

    public handleShowNotif(e: Event): void {
        this.appMain.toggleNotifications(e);

        if (!this.isNotificationHandled) {
            this.loadAnnounce = true;
            this.loadNotif = true;

            this._notifBell();
            this._announceBell();

            this.isNotificationHandled = true;
        }
    }

    public handleActionProcessMonitorings(data: any, action): void {
        if (action === 'download') {
            this._processQueueService.download(data?.processId).subscribe({
                next: (res) => {
                    saveAs(res, data?.fileName);
                },
            });
        }

        if (action === 'rollback') {
            this._processQueueService.rollback(data?.processId).subscribe({
                next: (res) => {
                    const { data } = JSON.parse(res);

                    this.msg.showSuccess(data, null, false);

                    // this.dataView?.onRefresh();
                },
            });
        }
    }

    public handleShowProcessMonitoring(e: Event): void {
        this.appMain.toggleQueueMonitoring(e);
    }

    private _notifTotalUnread(): Promise<any> {
        return lastValueFrom(this.notifSrv.getTotalUnread()).then(
            (res: any) => {
                let count: string = '0';
                if (res.data.count >= 99) {
                    count = '99+';
                } else {
                    count = String(res.data.count);
                }

                this.notifTotalUnread = count;
            }
        );
    }

    private _processQueueMonitoring(): any {
        return this._processQueueService.getProcessMonitoringPopUp().subscribe({
            next: (res) => {
                this.listProcessMonitorings = this.setListProcessMonitoring(
                    res?.data || []
                );
                this.totalProcessMonitorings = res?.paging?.totalItem || 0;
            },
        });
    }

    private _notifBell(): Promise<any> {
        return lastValueFrom(this.notifSrv.getNotifBell())
            .then((res: any) => {
                this.notifListBel = res.data;

                this.loadNotif = false;
            })
            .finally(() => (this.loadNotif = false));
    }

    private _announceBell(): Promise<any> {
        return lastValueFrom(this.notifSrv.getAnnounceBell())
            .then((res: any) => {
                this.announceListBel = res.data;

                this.loadAnnounce = false;
            })
            .finally(() => (this.loadAnnounce = false));
    }

    private _announcementShow(triggerSock: boolean = false): any {
        return lastValueFrom(this.notifSrv.getAnnounceShow()).then(
            (res: any) => {
                if (res.data && res.data.length) {
                    this.announceList = res.data;
                    const displayAnnAdmin =
                        localStorage.getItem('displayAnnAdmin');

                    if (!displayAnnAdmin || displayAnnAdmin === 'true') {
                        localStorage.setItem('displayAnnAdmin', 'true');
                        this.visibleAnn = true;
                    } else if (triggerSock) {
                        localStorage.setItem('displayAnnAdmin', 'true');
                        this.visibleAnn = true;
                    }
                }
            }
        );
    }

    private _flashBannerShow(triggerSock: boolean = false): any {
        return lastValueFrom(this.notifSrv.getFlashBannerShow()).then(
            (res: any) => {
                if (res.data && res.data.length) {
                    this.flashBannerList = res.data;
                    const displayFlashAdmin =
                        localStorage.getItem('displayFlashAdmin');

                    if (!displayFlashAdmin || displayFlashAdmin === 'true') {
                        localStorage.setItem('displayFlashAdmin', 'true');
                        this.visibleFlash = true;
                    } else if (triggerSock) {
                        localStorage.setItem('displayFlashAdmin', 'true');
                        this.visibleFlash = true;
                    }
                }
            }
        );
    }

    onHideAnnounce() {
        localStorage.setItem('displayAnnAdmin', 'false');
        this.visibleAnn = false;
    }

    doLogout() {
        this._webSocketService.disconnect();
        this.api.logout().subscribe({
            next: (resp) => {
                this.auth.destroySession();
                this.route.navigate(['/login']);
            },
            error: (err) => {
                this.auth.destroySession();
                this.route.navigate(['/login']);
            },
        });

        // if (environment.isSSO) {
        //     this.auth.destroySession();
        //     window.location.href = environment.linovWordpress;
        // } else {
        //     this.route.navigate(['/login']);
        // }
    }

    selectCompany(e) {
        if (this.session?.selectedCompanyId != e.id) {
            this.config.setLoading(true);
            this.api
                .post('auth/change-company', { id: e.id }, true)
                .subscribe((res) => {
                    this.auth.createSession(res);
                    this.session = this.auth.getSession();
                    this.config.setLoading(false);
                });
        }
    }

    defaultLangChecker() {
        this.translate.addLangs(['en', 'id']);

        if (localStorage.getItem('locale')) {
            const browserLang = localStorage.getItem('locale');
            this.translate.use(browserLang.match(/en|id/) ? browserLang : 'en');
        } else {
            localStorage.setItem('locale', 'en');
            this.translate.setDefaultLang('en');
        }
    }

    changeLang(language: string) {
        localStorage.setItem('locale', language);
        this.translate.use(language);
    }

    async getDataCompany() {
        await lastValueFrom(this.srv.getCompany())
            .then((res) => {
                this.companyId = res.data[0].name;
                this.auth.setLocalStorage(
                    AppConstant.companyId,
                    res.data[0].id
                );
            })
            .catch((error) => {});
    }

    onShowNotif() {
        const web = localStorage.getItem('web');
        this.appMain.hideNotifications();

        this.route.navigateByUrl(`/${web}/notifications/list`);
    }

    onViewAllQueues() {
        const web = localStorage.getItem('web');
        this.appMain.hideQueueMonitoring();

        this.route.navigateByUrl(`/${web}/process-queue-monitoring/list`);
    }

    onChangeWeb(web) {
        localStorage.setItem('web', web);

        this.route.navigate(['/' + web]);
    }

    search(event: AutoCompleteCompleteEvent) {
        this.suggestions = this.auth
            .getLocalStorage(AppConstant.accessAdmin)
            .filter((el: any) =>
                el.label.toLowerCase().includes(event.query.toLowerCase())
            );
    }

    select(event: any) {
        this._router.navigateByUrl(
            `${this._web}${event.routerLink.toString()}`
        );
    }

    handleClickNotif(data) {
        let route = data.route.path;

        if (route) {
            this.checkAndCorrectRoute(route, data);
        } else {
            this.msg.showError('Invalid Route');
        }
    }

    handleClickAnnounce(data) {
        this._router.navigateByUrl(
            `${localStorage.getItem('web')}/notifications/detail/${
                data.id
            }/announcement`
        );
    }

    async checkAndCorrectRoute(routePath: string, data: any) {
        const is404 = await this.isRoute404(routePath);
        const web = routePath.split('/')[0].toLowerCase();

        if (is404) {
            this.msg.showError(
                `Route ${routePath} leads to 404. No navigation performed.`
            );
            return;
        }

        const matchedAccess = Object.entries(this.appInfo).find(
            ([key, hasAccess]) =>
                key.includes('has') &&
                key.replace(/has|Access/g, '').toLowerCase() === web &&
                hasAccess
        );

        await lastValueFrom(this.notifSrv.markAsRead(data.id));
        this._notifBell();
        this._notifTotalUnread();

        if (matchedAccess) {
            this.appMain.hideNotifications();
            window.open(routePath, '_blank');
        } else {
            this.msg.showWarn(
                `No access found for ${
                    data?.applicationType || 'Admin'
                }. Navigation to ${routePath} is not allowed.`
            );
        }
    }

    async isRoute404(routePath: string): Promise<boolean> {
        const urlTree: UrlTree = this._router.parseUrl(routePath);
        const is404 = urlTree.root.children.primary === null;
        return is404;
    }

    formatDateNotifAnnounce(dateString: string) {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        const millisecondsDiff = currentDate.getTime() - inputDate.getTime();
        const daysDiff = Math.floor(millisecondsDiff / (1000 * 3600 * 24));

        if (daysDiff === 0) {
            return 'Today';
        } else if (daysDiff >= 1 && daysDiff <= 7) {
            return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
        } else {
            const formattedDate = `${this.getDayNameShort(
                inputDate.getDay()
            )}, ${inputDate.getDate()} ${this.getMonthNameShort(
                inputDate.getMonth()
            )}`;
            return formattedDate;
        }
    }

    getDayNameShort(day: number): string {
        const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return daysShort[day];
    }

    getMonthNameShort(month: number): string {
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return months[month];
    }

    getImageUrl(item, flash = false): string {
        const foundItem = flash ? item.file.base : item.bannerFile?.file.base;
        if (foundItem) {
            try {
                const imageUrl = this.base64toUrl(foundItem);
                return imageUrl;
            } catch (error) {
                return 'assets/images/no-image-announcement.png';
            }
        } else {
            return 'assets/images/no-image-announcement.png';
        }
    }

    private base64toUrl(base64String: string): string {
        return 'data:image/*;base64,' + base64String;
    }

    getPageAnnouncement(item) {
        return this.announceList.findIndex((obj) => obj.id === item.id) + 1;
    }

    redirectToAnnouncement(item) {
        this._router.navigateByUrl(
            `${localStorage.getItem('web')}/notifications/detail/${
                item.id
            }/announcement`
        );
    }

    onPageChangeFlashBanner(e) {
        this.pageFlashBanner = e.page;
    }

    private setListProcessMonitoring(data: any[]): any[] {
        return (
            data?.map((item) => {
                const status = item?.status?.toLowerCase();
                let time = '';

                if (status === 'finish') {
                    time = this._helperSrv.convertDateToDateMonthYear(
                        item?.processDateTime,
                        'fullWithTime'
                    );
                } else if (status === 'on queue') {
                    time = this._helperSrv.convertDateToDateMonthYear(
                        item?.startQueue,
                        'fullWithTime'
                    );
                } else if (status === 'on process') {
                    time = this._helperSrv.convertDateToDateMonthYear(
                        item?.endQueue,
                        'fullWithTime'
                    );
                }

                return {
                    processId: item?.id || '',
                    queueNo: item?.queueNumber || '',
                    queueStatus: item?.status || '',
                    processName: item?.trxName || '',
                    processType: item?.trxType || '',
                    time,
                    fileName: item?.result || '',
                };
            }) || []
        );
    }

    ngOnDestroy(): void {
        this._webSocketService.disconnect();
    }
}
