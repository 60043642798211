import { Input, Output, Component, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'input-password',
    styleUrls: ['./input-password.component.scss'],
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputPasswordComponent,
        },
    ],
    templateUrl: './input-password.component.html',
})
export class InputPasswordComponent implements ControlValueAccessor {
    @Input()
    public id!: string | undefined;

    @Input()
    public showInfo: boolean = false;
    @Input()
    public showGenerator: boolean = false;

    @Output('onChange')
    private readonly _onChange: EventEmitter<any> = new EventEmitter<any>();

    public info: string = '';
    public tips: string[] = [];
    public value: string = '';
    public touched: boolean = false;
    public disabled: boolean = false;
    public onChange: Function = () => {};
    public onTouched: Function = () => {};
    public showPassword: boolean = false;

    private _generatePassword(): string {
        const length: number = 8;
        const charset: string = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789~\`!@#$%^&*()_-+={[}]|\:;"'<,>.?/`;

        let retVal: string = '';

        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }

        return retVal;
    }

    private _setValue(value: string): void {
        this.markAsTouched();

        if (!this.disabled) {
            this.onChange(value);
        }
    }

    private _checkPasswordStrength(value: string): void {
        const tips: Set<string> = new Set();

        let strength: number = 0;

        if (value.length < 8) {
            tips.add('• Make the password longer.');
        } else {
            strength += 1;
        }

        if (value.match(/[a-z]/) && value.match(/[A-Z]/)) {
            strength += 1;
        } else {
            tips.add('• Use both lowercase and uppercase letters.');
        }

        if (value.match(/\d/)) {
            strength += 1;
        } else {
            tips.add('• Include at least one number.');
        }

        if (value.match(/[^a-zA-Z\d]/)) {
            strength += 1;
        } else {
            tips.add('• Include at least one special character.');
        }

        const suffix: string = tips.size && this.showInfo ? ', tips:' : '.';

        if (strength < 2) {
            this.info = 'Easy to guess' + suffix;
        } else if (strength === 2) {
            this.info = 'Medium difficulty' + suffix;
        } else if (strength === 3) {
            this.info = 'Difficult' + suffix;
        } else {
            this.info = 'Extremely difficult' + suffix;
        }

        this.tips = Array.from(tips);
    }

    public generateRandomPassword(): void {
        let tempValue: string = this._generatePassword();

        this.showPassword = true;

        this._checkPasswordStrength(tempValue);

        while (this.tips.length) {
            tempValue = this._generatePassword();

            this._checkPasswordStrength(tempValue);
        }

        this.value = tempValue;

        this._setValue(this.value);
    }

    public handleChange(event: Event): void {
        this.value = (event.target as HTMLInputElement).value.trim();

        this._checkPasswordStrength(this.value);

        this._setValue(this.value);

        this._onChange.emit();
    }

    public markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();

            this.touched = true;
        }
    }

    public registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    public writeValue(value: string): void {
        this.value = value;
    }
}
