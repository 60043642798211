<div class="card grid min-h-screen p-0 m-0">
    <div class="lg:col-6 hide-mobile p-0 m-0 login-bg-left">
        <div class="grid px-4 py-4">
            <div class="col-12 center-label">
                <img
                    src="assets/logo/LinovHR.svg"
                    width="85px"
                    height="16.86px"
                />
            </div>
        </div>

        <div class="grid" style="margin-top: 4.5rem">
            <div class="col-12">
                <p-carousel
                    [value]="images"
                    [autoplayInterval]="5000"
                    [circular]="true"
                    orientation="none"
                    [numVisible]="1"
                    [numScroll]="1"
                    [responsiveOptions]="responsiveOptions"
                >
                    <ng-template let-item pTemplate="item">
                        <div class="text-center">
                            <img
                                src="{{ item.itemImageSrc }}"
                                [alt]="item.title"
                                width="515px"
                                height="436px"
                            />
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6 m-0 p-0">
        <div class="container">
            <div class="login-box p-2">
                <div class="w-full flex flex-column" style="gap: 22px">
                    <div class="w-full flex justify-content-center">
                        <span
                            class="flex align-items-center justify-content-center w-4rem h-4rem border-circle"
                            style="background-color: #e8f2ff"
                        >
                            <i
                                class="fa-solid fa-user-lock text-3xl text-primary"
                            ></i>
                        </span>
                    </div>
                    <span class="w-full text-center p-title">
                        Authenticate Your Account
                    </span>
                    <span
                        class="text-center text-black text-lg"
                        style="line-height: 20px"
                    >
                        Please enter the 6-digit OTP code we’ve sent to<br />
                        <b>{{ otpInfo?.email || "-" }}</b
                        ><br />This code will be valid for 5 minutes.
                    </span>
                    <span
                        class="w-full text-center text-lg text-black"
                        *ngIf="isTimeOver; else countdown"
                    >
                        Haven’t received the code?
                        <button
                            class="btn-nude cursor-pointer text-lg text-primary"
                            style="text-decoration: underline"
                            (click)="handleResendCodeOTP()"
                        >
                            Resend a new code
                        </button>
                    </span>
                    <ng-template #countdown>
                        <span class="text-primary w-full text-center">
                            {{ formatTime(countdownTime) }}
                        </span>
                    </ng-template>
                    <div
                        class="w-full flex flex-column align-items-center text-lg"
                        style="gap: 14px"
                    >
                        <ngx-otp-input
                            [options]="otpOptions"
                            (otpComplete)="handleCompleteOtp($event)"
                            (otpChange)="handleChangeOtp($event)"
                        ></ngx-otp-input>
                        <div
                            class="flex align-items-center justify-content-center"
                            style="gap: 8px"
                            *ngIf="invalidOtp"
                        >
                            <i
                                class="fa-solid fa-triangle-exclamation text-red"
                            ></i>
                            <span class="text-red font-medium text-center"
                                >The code you entered is invalid</span
                            >
                        </div>
                    </div>
                    <div class="w-full">
                        <button
                            pButton
                            pRipple
                            class="w-full"
                            label="Submit"
                            (click)="handleSubmitCodeOtp()"
                            [disabled]="!filledCode"
                            [loading]="isLoading || loginComponent.isLoading"
                        ></button>
                    </div>
                    <div class="w-full flex justify-content-center">
                        <span
                            class="text-primary text-lg cursor-pointer"
                            (click)="onBack()"
                        >
                            Back To Sign In
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<flash-message-success></flash-message-success>
<!-- <ngx-spinner></ngx-spinner> -->
