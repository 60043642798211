import {
    Component,
    AfterViewInit,
    OnDestroy,
    Renderer2,
    OnInit,
} from '@angular/core';
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ConfigService } from 'src/app/core/service/app.config.service';
import { AppConfig } from 'src/app/core/config/app.config';
import jwt_decode from 'jwt-decode';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/core/service/helper-service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ApiServiceEss } from 'src/app/core/service/api.service-ess';
import { AppConstant } from '@config/app.config';

@Component({
    selector: 'app-main-ess',
    templateUrl: './app.main.component.html',
    styles: [
        `
            :host ::ng-deep .p-message {
                margin-left: 0.25em;
            }

            :host ::ng-deep .p-toast {
                margin-top: 5.7em;
                z-index: 99999;
            }
        `,
    ],
    animations: [
        trigger('submenu', [
            state(
                'hidden',
                style({
                    height: '0px',
                })
            ),
            state(
                'visible',
                style({
                    height: '*',
                })
            ),
            transition(
                'visible => hidden',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
            ),
            transition(
                'hidden => visible',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
            ),
        ]),
    ],
})
export class AppMainEssComponent implements AfterViewInit, OnDestroy, OnInit {
    public menuInactiveDesktop: boolean;

    public menuActiveMobile: boolean;

    public overlayMenuActive: boolean = false;

    public staticMenuInactive: boolean = false;

    public profileActive: boolean;

    public topMenuActive: boolean;

    public topMenuLeaving: boolean;

    public theme: string;

    width;

    documentClickListener: () => void;

    menuClick: boolean;

    topMenuButtonClick: boolean;

    configActive: boolean;

    configClick: boolean;

    config: AppConfig;

    subscription: Subscription;

    premium = false;

    trialDaysExpired = false;

    trialDays;

    closed = false;
    accountBtnClick: boolean = false;

    public menuActiveNotification: boolean | undefined;
    public menuNotificationLeaving: boolean | undefined;
    public menuNotificationButtonClick: boolean | undefined;
    public notifBtnClick: boolean = false;

    constructor(
        public renderer: Renderer2,
        public app: AppComponent,
        public configService: ConfigService,
        private auth: AuthenticationService,
        private help: HelperService,
        private api: ApiServiceEss,

        private _router: Router,
        private _helperService: HelperService
    ) {
        this.renderer.listen('window', 'click', (e: Event) => {
            // if (!this.accountBtnClick) {
            //     this.topMenuActive = false;
            // }
            this.accountBtnClick = false;
            this.notifBtnClick = false;
        });
    }

    async ngOnInit() {
        const web: string = this._router.url.split('/')[1];

        localStorage.setItem('web', web);

        // TODO: Uncomment this for rbac
        if (!this._helperService.hasAccessToWeb(web)) {
            this._router.navigateByUrl('403');

            return;
        }

        this.width = window.innerWidth;
        this.config = this.configService.config;
        this.subscription = this.configService.configUpdate$.subscribe(
            (config) => (this.config = config)
        );
        let tokenStr = this.auth.getSession()?.accessToken;
        let token: any = jwt_decode(tokenStr);
        if (tokenStr && new Date(token.exp * 1000) < new Date()) {
            this.auth.destroySession();
            console.log('jika terjadi keanehan harap cek jam device anda');
            this._router.navigate(['login']);
        }
        this.premium = this.help.checkStat(true);
        // this.api
        //     .get('company/day-subscription')
        //     .toPromise()
        //     .then((res) => {
        //         this.trialDays = res.day;

        //         if (this.trialDays <= 30) {
        //             this.trialDaysExpired = true;
        //         }
        //     });
    }

    ngAfterViewInit() {
        // hides the overlay menu and top menu if outside is clicked
        this.documentClickListener = this.renderer.listen(
            'body',
            'click',
            (event) => {
                if (!this.isDesktop()) {
                    if (!this.menuClick) {
                        this.menuActiveMobile = false;
                    }
                } else {
                    if (!this.menuClick && this.isOverlay()) {
                        this.menuInactiveDesktop = true;
                    }
                    if (!this.menuClick) {
                        this.overlayMenuActive = false;
                    }
                }
                if (!this.topMenuButtonClick) {
                    if (
                        event?.path?.filter((pat) => pat.id == 'changeComp')
                            .length <= 0
                    ) {
                        this.hideTopMenu();
                    }
                }

                if (!this.menuNotificationButtonClick) {
                    if (
                        event?.path?.filter((pat) => pat.id == 'changeComp')
                            .length <= 0
                    ) {
                        this.hideNotifications();
                    }
                }

                if (this.configActive && !this.configClick) {
                    this.configActive = false;
                }

                this.configClick = false;
                this.menuClick = false;
                this.topMenuButtonClick = false;
                this.menuNotificationButtonClick = false;
            }
        );
    }

    isBurgerMenuClicked = true;
    toggleMenu(event: Event) {
        this.menuClick = true;
        this.staticMenuInactive = !this.staticMenuInactive;
        this.menuActiveMobile = !this.menuActiveMobile;
        this.topMenuActive = false;
        this.configService.triggerToggleMenu();
        if (this.isBurgerMenuClicked) {
            if (document.getElementById('layout-menubar-ess')) {
                document.getElementById(
                    'layout-menubar-ess'
                ).style.paddingLeft = '2rem';
            }
            this.isBurgerMenuClicked = false;
        } else {
            if (document.getElementById('layout-menubar-ess')) {
                document.getElementById(
                    'layout-menubar-ess'
                ).style.paddingLeft = '19rem';
            }
            this.isBurgerMenuClicked = true;
        }

        event.preventDefault();
    }

    toggleProfile(event: Event) {
        this.profileActive = !this.profileActive;
        event.preventDefault();
    }

    toggleTopMenu(event: Event) {
        this.topMenuButtonClick = true;
        this.menuActiveMobile = false;

        if (this.topMenuActive) {
            this.hideTopMenu();
        } else {
            this.topMenuActive = !this.topMenuActive;
        }
        this.accountBtnClick = true;
        if (this.menuActiveNotification) {
            this.hideNotifications();
        }

        event.preventDefault();
    }

    hideTopMenu(eventRoute: boolean = false) {
        this.topMenuLeaving = true;
        setTimeout(() => {
            this.topMenuActive = eventRoute ? false : !this.topMenuActive;
            this.topMenuLeaving = false;
        }, 1);
    }

    toggleNotifications(event: Event) {
        this.menuNotificationButtonClick = true;

        if (this.menuActiveNotification) {
            this.hideNotifications();
        } else {
            this.menuActiveNotification = !this.menuActiveNotification;
        }

        this.notifBtnClick = true;

        if (this.topMenuActive) {
            this.hideTopMenu();
        }
        event.preventDefault();
    }

    hideNotifications(eventRoute: boolean = false) {
        this.menuNotificationLeaving = true;
        setTimeout(() => {
            this.menuActiveNotification = eventRoute
                ? false
                : !this.menuActiveNotification;
            this.menuNotificationLeaving = false;
        }, 1);
    }

    onMenuClick() {
        this.menuClick = true;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth < 1024;
    }

    onSearchClick() {
        this.topMenuButtonClick = true;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
