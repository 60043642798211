import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppConstant } from '@core/config/app.config';
import { AuthenticationService } from '@core/service/authentication.service';

@Component({
    selector: 'app-error-page-not-found',
    styleUrls: ['./page-not-found.component.scss'],
    templateUrl: 'page-not-found.component.html',
})
export class PageNotFoundComponent {
    private readonly _web: string | undefined = localStorage.getItem('web');

    constructor(
        private readonly _router: Router,
        private readonly _appService: AppService,
        private readonly _authenticationService: AuthenticationService
    ) {}

    public onReturnHome(): void {
        const info: any = this._authenticationService.getLocalStorage(
            AppConstant.info
        );

        let hasAccess: boolean = false;

        if (this._authenticationService.getLocalStorage(AppConstant.info)) {
            hasAccess = Object.values(info).every(
                (el: boolean) => typeof el === 'boolean'
            );
        }

        if (!hasAccess || !this._web) {
            this._authenticationService.destroySession();

            this._router.navigateByUrl('/login');

            return;
        }

        this._router.navigateByUrl(`/${this._web}`);
    }
}
