import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { AuthenticationService } from "@core/service/authentication.service";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({ providedIn: "root" })
export class AnalyticsApiService {
  protected header = new HttpHeaders();
  userSession;
  apiUrl = "https://api-analytic.linovhr.com/api/v1/";

  constructor(
    private httpClient: HttpClient,
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService
  ) {}

  getHeaders(): HttpHeaders {
    return (
        this.header
            .append(
                'Authorization',
                `Bearer ${this.auth.getSession()?.accessToken}`
            )
            // .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
      );
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    return throwError(() => err.error);
  }

  private mapToAppResponse(response: HttpResponse<any>): any {
    return response.body;
  }

  get(uri: string, param?: HttpParams, isShowSpinner?): Observable<any> {
    //pasang loading
    if (isShowSpinner) {
        this.spinner.show();
    }
    return this.httpClient
        .get<any>(this.apiUrl + uri, {
            headers: this.getHeaders(),
            observe: 'response',
            params: param,
        })
        .pipe(
            map((response) => this.mapToAppResponse(response)),
            catchError(this.handleError),
            finalize(() => {
                this.spinner.hide();
            })
        );
  }
}
