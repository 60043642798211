import { ApiService } from '@core/service/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserRoleService {
    constructor(private _apiService: ApiService) {}

    public checkIfCheckedAllSubmenus(
        data: any[],
        type?: string | undefined
    ): boolean {
        return data?.every((childMenu: any) => {
            const propertyValues: any = Object.keys(childMenu)
                .filter(
                    (key: string) =>
                        key.startsWith('has') && childMenu[key] === true
                )
                .reduce((obj: any, key: string) => {
                    const infix: string = key.replace('has', '');

                    obj[key] = childMenu[key];
                    obj[`is${infix}`] = childMenu[`is${infix}`];

                    if (type === 'Admin') {
                        this.filterAccessAdmin(obj);
                    }

                    if (['ESS', 'MSS'].includes(type)) {
                        this.filterAccessEssMss(obj);
                    }

                    return obj;
                }, {});

            return Object.values(propertyValues).every(Boolean);
        });
    }

    public filterAccessAdmin(obj: any): void {
        const properties: string[] = [
            'isCancelRequest',
            'isCreateRequest',
            'hasCancelRequest',
            'hasCreateRequest',
        ];

        for (const property of properties) {
            delete obj[property];
        }
    }

    public isSomeChildMenuActive(childMenus: any[]): boolean {
        return childMenus?.some((item: any): boolean => {
            return Object.keys(item).some((key): boolean => {
                return key.startsWith('is') && item[key];
            });
        });
    }

    public filterAccessEssMss(obj: any): void {
        const properties: string[] = ['isAll', 'hasAll'];

        for (const property of properties) {
            delete obj[property];
        }
    }

    public restructuredAccesses(data: any[]): any[] {
        return data.map((category: any) => {
            const isAccessEssMss: boolean = ['ESS', 'MSS'].includes(
                category.name
            );

            return {
                id: category.id,
                name: category.name,
                modules: category.modules.map((module: any): any => ({
                    id: module.id,
                    name: module.name,
                    menus: module.menus.map((menu: any): any => {
                        return {
                            id: menu.id,
                            name: menu.name,
                            active: isAccessEssMss
                                ? menu.isAccessible ||
                                  this.isSomeChildMenuActive(menu.childMenus)
                                : this.isSomeChildMenuActive(menu.childMenus),
                            giveAccessibilityToAllSubmenu:
                                this.checkIfCheckedAllSubmenus(
                                    menu.childMenus,
                                    category.name
                                ),
                            childMenus: menu.childMenus?.map(
                                (submenu: any): any => submenu
                            ),
                        };
                    }),
                })),
            };
        });
    }

    public verifyAccesses(data: any[], isDetailsMode?: boolean): any[] {
        const filterChildMenus = (childMenu: any): any => {
            const initialChildMenu: any = {
                name: childMenu.name,
                parentName: childMenu.parentName,
            };

            return Object.keys(childMenu)
                .filter(
                    (key: string): boolean =>
                        key.startsWith('has') && childMenu[key] === true
                )
                .reduce(
                    (obj: any, key: string): void => {
                        const infix: string = key.replace('has', '');

                        obj.id = childMenu.id;

                        obj[key] = childMenu[key];
                        obj[`is${infix}`] = childMenu[`is${infix}`];

                        return obj;
                    },
                    isDetailsMode ? initialChildMenu : {}
                );
        };

        const filterMenus = (
            { childMenus, ...menuRest }: any,
            accessName: string
        ) => {
            if (
                ['ESS', 'MSS'].includes(accessName) &&
                !childMenus.length &&
                menuRest.isAccessible
            ) {
                return {
                    ...menuRest,
                    childMenus,
                };
            }

            const filteredChildMenus = childMenus.map(filterChildMenus);

            return childMenus.length
                ? {
                      ...menuRest,
                      childMenus: isDetailsMode
                          ? filteredChildMenus
                          : childMenus,
                  }
                : null;
        };

        const filterModules = (
            { menus, ...moduleRest }: any,
            accessName: string
        ) => {
            const filteredMenus = menus
                .map((el: any) => filterMenus(el, accessName))
                .filter(Boolean);

            return filteredMenus.length
                ? { ...moduleRest, menus: filteredMenus }
                : null;
        };

        const filterAccesses = (
            { modules, ...rest }: any,
            accessName: string
        ) => {
            const filteredModules = modules
                .map((el: any): any => filterModules(el, accessName))
                .filter(Boolean);

            return filteredModules.length
                ? { ...rest, modules: filteredModules }
                : null;
        };

        return data
            .map((el: any): any => filterAccesses(el, el.name))
            .filter(Boolean);
    }

    public getAccesses(id: string): Observable<any> {
        return this._apiService.get(
            `role-menus/${id}/access`,
            null,
            false,
            false,
            () => {}
        );
    }

    public getRoleTrusteesRequirements(): Observable<any> {
        return this._apiService.get('role-trustees/requirements');
    }

    public saveRole(data: any): Observable<any> {
        return this._apiService.post('roles', data, true);
    }

    public deleteRole(id: string): Observable<any> {
        return this._apiService.delete(`roles/${id}`, true);
    }

    public deleteRoles(data: any): Observable<any> {
        return this._apiService.deleteAll('roles', data, true);
    }

    public deleteRoleTrustees(data: any): Observable<any> {
        return this._apiService.deleteAll('role-trustees/bulk', data, true);
    }

    public getRole(id: string): Observable<any> {
        return this._apiService.get(`roles/${id}`, null, true, false, () => {});
    }

    public getApplications(): Observable<any> {
        return this._apiService.get('lovs/applications');
    }

    public getAttributes(): Observable<any> {
        return this._apiService.get('role-trustees/attributes');
    }

    public saveMenuAccessibility(data: any): Observable<any> {
        return this._apiService.post('role-menus', data);
    }

    public saveRoleTrustee(data: any): Observable<any> {
        return this._apiService.post('role-trustees', data, true);
    }

    public saveRoleMenuTab(data: any): Observable<any> {
        return this._apiService.post('role-menu-tabs', data);
    }

    public getJobLevels(ids: string): Observable<any> {
        return this._apiService.get(
            `lovs/job-levels?companyIds=${ids}&isActive=true`
        );
    }

    public getCostCenters(ids: string): Observable<any> {
        return this._apiService.get(
            `lovs/cost-centers?companyIds=${ids}&isActive=true`
        );
    }

    public getOrganizations(ids: string): Observable<any> {
        return this._apiService.get(
            `lovs/organizations?companyIds=${ids}&isActive=true`
        );
    }

    public getEmployeeTypes(ids: string): Observable<any> {
        return this._apiService.get(
            `lovs/employee-types?companyIds=${ids}&isActive=true`
        );
    }

    public getWorkLocations(ids: string): Observable<any> {
        return this._apiService.get(
            `lovs/work-locations?companyIds=${ids}&isActive=true`
        );
    }

    public getJobTitles(ids: string): Observable<any> {
        return this._apiService.get(
            `lovs/job-titles?companyIds=${ids}&isActive=true`
        );
    }

    public getRoleMenu(id: string): Observable<any> {
        return this._apiService.get(
            `role-menus/${id}`,
            null,
            true,
            false,
            () => {}
        );
    }

    public getRoleTrustees(data: { [key: string]: string }): Observable<any> {
        let uri: string = 'role-trustees?';

        if (data.roleId) {
            uri += `roleId=${data.roleId}`;
        }

        if (data.roleId && data.roleType) {
            uri += `&roleType=${data.roleType}`;
        }

        return this._apiService.get(uri);
    }

    public getMenuTab(menuId: string): Observable<any> {
        return this._apiService.get(`role-menu-tabs/${menuId}`);
    }

    public getMenuTabs(id: string): Observable<any> {
        return this._apiService.get(`menu-tabs/${id}`);
    }

    public getRoleMenuTab(id: { [key: string]: string }): Observable<any> {
        return this._apiService.get(`role-menu-tabs/${id.role}/${id.menu}`);
    }

    public getRoleTypes(): Observable<any> {
        return this._apiService.get('roles/types');
    }

    public getRoleMenuTabAccess(id: {
        [key: string]: string;
    }): Observable<any> {
        return this._apiService.get(
            `role-menu-tabs/${id.role}/${id.menu}/access`,
            null,
            false,
            false,
            () => {}
        );
    }

    public updateUserRole(data: any): Observable<any> {
        return this._apiService.put('roles', data, true);
    }

    public updateRoleTrustee(data: any): Observable<any> {
        return this._apiService.put('role-trustees', data, true);
    }

    public getReportingLine(): Observable<any> {
        return this._apiService.get('role-trustees/reporting-line-access');
    }

    public getAccessMenuRole(type: string): Observable<any> {
        return this._apiService.get(`role-menus/trustees/flat/${type}`);
    }

    public getMenuAndSubmenuAdmin(): Observable<any> {
        return this._apiService.get('role-menus/trustees/structured');
    }
}
