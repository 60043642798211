import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@core/service/api.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { MessageBoxService } from '@core/service/message-box.service';
import { LoginComponent } from '@layout/login/login.component';
import { NgxOtpInputComponentOptions } from 'ngx-otp-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, lastValueFrom, Subscription } from 'rxjs';

@Component({
    selector: 'app-otp-verification',
    templateUrl: 'otp-verification.component.html',
    styleUrls: ['otp-verification.component.scss'],
})
export class OtpVerificationComponent implements OnInit, OnDestroy {
    public images: any[] = [];
    public readonly responsiveOptions: any[] = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1,
        },
    ];

    public countdownTime: number = 120;
    public countdownSubscription!: Subscription | undefined;
    public isTimeOver: boolean = false;

    public readonly otpInfo!: any | undefined;
    public readonly otpOptions!: NgxOtpInputComponentOptions | undefined;

    public formGroup!: FormGroup | undefined;
    public invalidOtp!: boolean | undefined;
    public filledCode!: boolean | undefined;

    public isLoading!: boolean | undefined;

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthenticationService,
        private readonly formBuilder: FormBuilder,
        private readonly apiService: ApiService,
        private readonly router: Router,
        public readonly loginComponent: LoginComponent,
        private readonly msg: MessageBoxService
    ) {
        this.otpInfo = this.auth.getLocalStorage('otpInfo');
        this.otpOptions = {
            showBlinkingCursor: true,
            autoBlur: false,
            autoFocus: true,
        };
        this.formGroup = this.formBuilder.group({
            username: [this.otpInfo?.username || ''],
            otp: [''],
            deviceName: [this.otpInfo?.deviceName || ''],
        });
    }

    async ngOnInit() {
        try {
            this.images = await this.getImages();

            const savedTime = localStorage.getItem('otpCountdown');
            const isTimeOver = localStorage.getItem('isTimeOver') === 'true';

            if (savedTime) {
                this.countdownTime = parseInt(savedTime, 10);
                this.startCountdown();
            } else if (!isTimeOver) {
                this.startCountdown();
            } else {
                this.countdownTime = 0;
                this.isTimeOver = true;
            }
        } catch (error) {}
    }

    private getImages(): Promise<any[]> {
        return lastValueFrom(this.http.get<any>('assets/demo/data/photos.json'))
            .then((res) => <any[]>res.data)
            .then((data) => {
                return data;
            });
    }

    private startCountdown(): void {
        this.countdownSubscription = interval(1000).subscribe(() => {
            if (this.countdownTime > 0) {
                this.isTimeOver = false;

                --this.countdownTime;

                localStorage.setItem(
                    'otpCountdown',
                    this.countdownTime.toString()
                );
            } else {
                this.isTimeOver = true;

                localStorage.removeItem('otpCountdown');
                localStorage.setItem(
                    'isTimeOver',
                    JSON.stringify(this.isTimeOver)
                );
            }
        });
    }

    public formatTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${
            remainingSeconds < 10 ? '0' : ''
        }${remainingSeconds}`;
    }

    public handleCompleteOtp(data: string): void {
        this.formGroup.get('otp').patchValue(data);
    }

    public handleChangeOtp(otpArray: string[]): void {
        this.filledCode =
            otpArray.every((value) => value !== '') && otpArray.length === 6;

        if (!this.filledCode) {
            this.formGroup.get('otp').patchValue('');
        }
    }

    public handleResendCodeOTP(): void {
        this.apiService
            .postLogin(
                'auth/secure/otp/resend',
                {
                    username: this.otpInfo?.username || '',
                    deviceName: this.otpInfo?.deviceName || '',
                },
                true
            )
            .subscribe({
                next: (res) => {
                    this.countdownSubscription?.unsubscribe();

                    this.msg.showSuccess(
                        'A new OTP has been sent. Please check your email and enter the code.',
                        null,
                        false
                    );

                    this.countdownTime = 120;
                    this.isTimeOver = false;

                    this.startCountdown();

                    localStorage.setItem(
                        'otpCountdown',
                        JSON.stringify(this.countdownTime)
                    );
                    localStorage.setItem('isTimeOver', JSON.stringify(false));
                },
                error: (err) => {},
            });
    }

    public handleSubmitCodeOtp(): void {
        this.isLoading = true;
        this.apiService
            .postLogin('auth/secure/otp', {
                ...this.formGroup.getRawValue(),
            })
            .subscribe({
                next: (res) => {
                    const { data } = JSON.parse(res);

                    this.invalidOtp = false;

                    this.loginComponent.initializeUserSession(data, false, true);
                },
                error: (error) => {
                    const { reason: errors } = JSON.parse(error)?.errors;

                    this.invalidOtp =
                        errors?.[0]?.includes('Expired') ||
                        errors?.[0]?.includes('wrong');
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
    }

    public onBack(): void {
        this.router.navigateByUrl('/login');
    }

    ngOnDestroy(): void {
        localStorage.removeItem('otpCountdown');
        localStorage.removeItem('isTimeOver');
        localStorage.removeItem('otpInfo');

        if (this.countdownSubscription) {
            this.countdownSubscription.unsubscribe();
        }
    }
}
