export default [
    {
        items: [
            {
                label: 'Dashboard Analytics',
                icon: 'fas fa-sitemap',
                routerLink: ['/admin/analytics'],
                subMenu: [
                    {
                        menuId: '0c8d89c0-1226-45df-92d5-8c6a2d8a6a15',
                        label: 'Employee Demography',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'a51ac9a3-0f09-4c2b-a85f-3abb6ad817d3',
                                label: 'Overview',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/employee-demography/overview',
                                ],
                            },
                            {
                                menuId: '59f8119d-ae0a-41dc-a05a-588cb03ebdcc',
                                label: 'Hire',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/employee-demography/hire',
                                ],
                            },
                            {
                                menuId: '31991164-9e13-4bc3-86de-da10d2c42c09',
                                label: 'Movements',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/employee-demography/movements',
                                ],
                            },
                            {
                                menuId: 'ec6a9575-8d06-496c-bc0f-fcbbcac5f050',
                                label: 'Separation',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/employee-demography/separation',
                                ],
                            },
                            {
                                menuId: '7841f037-6bed-4496-ad6e-ca61fbc2afbb',
                                label: 'Reward & Punishment',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/employee-demography/reward-and-punishment',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'ed84e22d-91f4-4ab8-8356-3d470ab112ec',
                        label: 'Time & Attendance',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'b8e547ad-f1e4-4a26-ad65-8bb3e7f11a66',
                                label: 'Attendance',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/time-attendance/attendance',
                                ],
                            },
                            {
                                menuId: '33e355b3-49cd-433c-8694-569b57c6f3b1',
                                label: 'Unattendance',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/time-attendance/unattendance',
                                ],
                            },
                            {
                                menuId: '93b2bd0c-711a-4b66-92bd-ae0a75e01654',
                                label: 'Overtime',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/time-attendance/overtime',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'df933302-5433-4317-b095-4e8a29cba0b4',
                        label: 'Payroll',
                        styleClass: '',
                        items: [
                            {
                                menuId: '085fd7dd-14e4-40cb-9fb0-b7522aae5359',
                                label: 'Payroll',
                                styleClass: '',
                                routerLink: ['/admin/analytics/payroll'],
                            },
                            {
                                menuId: 'a2b348ca-9793-4b6c-8ea8-45e1e30b0d33',
                                label: 'Loan',
                                styleClass: '',
                                routerLink: ['/admin/analytics/payroll/loan'],
                            },
                        ],
                    },
                    {
                        menuId: '1311d071-9877-44d1-8c0d-3c061b631420',
                        label: 'Competency',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'f4a8b369-40b7-4e46-9dc7-0eecf8e7ce8a',
                                label: 'Review',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/competency/review',
                                ],
                            },
                            {
                                menuId: 'ce4dd5e7-f244-471f-9499-83d814a7873a',
                                label: 'Gap',
                                styleClass: '',
                                routerLink: ['/admin/analytics/competency/gap'],
                            },
                        ],
                    },
                    {
                        menuId: '4944f023-460c-44ad-8cdd-52c285ca1d47',
                        label: 'Performance',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'd31c38af-7251-498a-b37e-57b25cd32b3f',
                                label: 'Review',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/performance/review',
                                ],
                            },
                            {
                                menuId: '9336cd6f-4b71-46ad-abd0-d544e4fc17bb',
                                label: 'KPI',
                                styleClass: '',
                                routerLink: [
                                    '/admin/analytics/performance/kpi',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '5bf23746-9ce3-4ee6-b141-01212a578adb',
                        label: 'LMS',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'e00eaaf6-dcaa-4397-b734-1d168d543c3a',
                                label: 'e-Learning',
                                styleClass: '',
                                routerLink: ['/admin/analytics/lms/e-learning'],
                            },
                            {
                                menuId: '24b6b275-2ce5-4828-877e-661a97bf122b',
                                label: 'Training',
                                styleClass: '',
                                routerLink: ['/admin/analytics/lms/training'],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Workbench',
                icon: 'fas fa-toolbox',
                routerLink: ['/admin/workbench'],
                subMenu: [
                    {
                        menuId: '0ec0114c-ff40-4acd-a7ce-fe42d59e41f6',
                        label: 'General Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'a66f8819-eaf5-4ce1-8dda-d537a7d9e526',
                                label: 'System Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/system-configurations',
                                ],
                            },
                            {
                                menuId: 'd5838a8a-f7d5-48ba-a87c-15d89f89163b',
                                label: 'Module Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/module-configurations',
                                ],
                            },
                            {
                                menuId: '436d5125-c48d-42a5-81a7-a8b414e14fbb',
                                label: 'User Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/user-configurations',
                                ],
                            },
                            {
                                menuId: 'c10c6274-e066-4764-b9da-c4847e770dd1',
                                label: 'Mobile Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/mobile-configurations',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '142f13eb-3786-4975-ba1b-4f25c529f916',
                        label: 'User & Menu Authorization',
                        styleClass: '',
                        items: [
                            {
                                menuId: '35ab62ac-baef-464d-ba74-6748fc160228',
                                label: 'User Role',
                                styleClass: '',
                                routerLink: ['/admin/workbench/user-role/list'],
                            },
                            {
                                menuId: '3c905572-e370-41f9-b0c2-a879ae55b876',
                                label: 'User',
                                styleClass: '',
                                routerLink: ['/admin/workbench/user/list'],
                            },
                            {
                                menuId: '74420fe9-0f92-4e11-9b0d-0d367940d690',
                                label: 'Report Password',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/report-password/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'a7a386f8-76f7-4815-894f-be12379296e1',
                        label: 'Transaction Configuration',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'e53be72e-ea3e-45cc-8b1e-26a492420ca9',
                                label: 'Workflow Approval',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '1b716b81-ae29-4249-9fed-f21877a31454',
                                        label: 'Workflow Category',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workbench/workflow-category/list',
                                        ],
                                    },
                                    {
                                        menuId: '873db84f-b528-46ae-ad53-43309b5f3985',
                                        label: 'Workflow Template',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workbench/workflow-template/list',
                                        ],
                                    },
                                    {
                                        menuId: 'd78e2220-f112-4c72-b188-03f377f53374',
                                        label: 'Approval Group',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workbench/approval-group/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '28341ee8-0e6d-42ca-87f0-7b9fa7c0b9b6',
                                label: 'Transaction Settings',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/transaction-settings/list',
                                ],
                            },
                            {
                                menuId: 'e98df528-b328-4e76-9782-026f1d6e3410',
                                label: 'Numbering Format',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/numbering-format/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'c2128e68-ed71-44a4-8a88-62c855dc1a64',
                        label: 'Portal Configuration',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'fa084b6d-a621-4e4c-8d50-9d1db5e46281',
                                label: 'Announcement',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/announcement-panel/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'b6478c69-a53b-4b25-add7-5d9d46cd37c3',
                        label: 'Migration',
                        styleClass: '',
                        items: [
                            {
                                menuId: '82c363a5-a7d9-48c2-9386-4c8e71e0914a',
                                label: 'Migrations',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/migrations/upload',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '02bcbe39-d065-4c72-a803-d1647d948665',
                        label: 'Template Configuration',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'cec42d2b-8450-4181-bc8b-f31412993abd',
                                label: 'Letter Template',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/letter-template/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'b1536efa-91a5-4a9e-ad2c-bf5aa3f3627e',
                        label: 'Reminder & Scheduler Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '0b09e72e-5a60-4c62-b7cc-1cdb6a322947',
                                label: 'Manual Reminder Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/manual-reminder-configurations',
                                ],
                            },
                            {
                                menuId: '076154b5-67cb-47b5-b66c-682d6b4509da',
                                label: 'Reminder Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/reminder-configuration/list',
                                ],
                            },
                            {
                                menuId: 'e9520882-b860-4a39-b82c-0140f07d78f0',
                                label: 'Scheduler Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workbench/scheduler-configuration/list',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Organization Design',
                icon: 'fas fa-sitemap',
                routerLink: ['/admin/organization'],
                subMenu: [
                    {
                        menuId: '45bf44ba-fa09-472f-a4e7-8b8d22bb9b59',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '76c9ad03-65a0-465a-ab34-e1fae8fdcbcf',
                                label: 'Job Attribute',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/settings/job-attribute/list-combination',
                                ],
                            },
                            {
                                menuId: '62f3da79-4a42-4b5e-865d-a9ac6ed415cd',
                                label: 'Cost Center',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/settings/cost-center/list',
                                ],
                            },
                            {
                                menuId: '627af5dc-cb63-43f6-ad65-1e7b6c5cab9a',
                                label: 'MPP',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/settings/mpp/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'b0fac297-829a-47d5-9020-54afc0c3ffb6',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: '4420aa93-c553-4b43-a203-19590d2d9f24',
                                label: 'Company',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/activities/company/list',
                                ],
                            },
                            {
                                menuId: '6405b1b6-079d-4f5c-a8ec-56035fcb9280',
                                label: 'Organization Master',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/activities/organization-master-level/list',
                                ],
                            },
                            {
                                menuId: '4112cdef-5b35-40c3-9866-3ca7dfdf75aa',
                                label: 'Organization Structure',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/activities/organization-structure/list',
                                ],
                            },
                            {
                                menuId: 'd2f1da99-851f-49b1-bdea-523af0415f85',
                                label: 'Job Design',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'ced3eef9-b228-4bf5-851e-628658127be0',
                                        label: 'Job Title',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/job-title/list',
                                        ],
                                    },
                                    {
                                        menuId: '0bc06679-ab01-45d5-a98c-139599734d14',
                                        label: 'Job Level',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/job-level/list',
                                        ],
                                    },
                                    {
                                        menuId: 'f91542cb-0425-4fbf-9059-e43e1475e14d',
                                        label: 'Employee Type',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/employee-type/list',
                                        ],
                                    },
                                    {
                                        menuId: '73f3edaf-5b56-402d-bd57-ad49694f0351',
                                        label: 'Job Position',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/job-position/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'ef3e18e5-4232-42d5-9774-8c9265f2405a',
                                label: 'Work Location',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/activities/work-location/list',
                                ],
                            },
                            {
                                menuId: 'bf91ed36-a3b3-4a68-82e0-19ada048f056',
                                label: 'Cost Center',
                                styleClass: '',
                                routerLink: [
                                    '/admin/organization/activities/cost-center/list',
                                ],
                            },
                            {
                                menuId: '20e10966-44b0-4334-9548-454a77cdb386',
                                label: 'Man Power Planning',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'ed2f5ce1-a83d-4692-81da-fa63bb94f9e7',
                                        label: 'Period',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/mpp-period/list',
                                        ],
                                    },
                                    {
                                        menuId: '47eb8311-6104-4d5c-89de-68dc6e038017',
                                        label: 'Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/mpp-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '31a82748-fa74-43d8-93a3-138e4d8f2d51',
                                        label: 'Balance Display',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/mpp-balance-display/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '83e35dc4-b9fb-498d-a206-3dd8f39e4d7d',
                                label: 'Job Attribute Requests',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '7d532bb7-ea92-494a-b0e6-a91a3c35e398',
                                        label: 'Organization Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/organization-request/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Workforce',
                icon: 'fas fa-users',
                routerLink: ['/admin/workforce'],
                subMenu: [
                    {
                        menuId: '74efddc8-cbe6-4bf7-a6de-163feb6d7205',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'caf93be0-d432-4a55-8c51-43d4f95b99fa',
                                label: 'General Settings',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '0d2b53b4-fef1-4804-bac1-53156b50b813',
                                        label: 'Bank Master',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/bank-account/list',
                                        ],
                                    },
                                    {
                                        menuId: '0c59e476-0f67-4e6a-9df1-ba9e10723255',
                                        label: 'Digital Signature',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/digital-signature/list',
                                        ],
                                    },
                                    {
                                        menuId: 'e45f861d-c1c7-451c-9307-87c5c9d53cf8',
                                        label: 'Retirement Period',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/retirement-period/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '1076c38a-6fc4-4f19-a4a0-d433c7149c47',
                                label: 'Numbering',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '5a98a59b-1560-4c07-a255-41d8d94f5fd4',
                                        label: 'Employee Number Format',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/numbering/general-settings-member-by/detail',
                                        ],
                                    },
                                    {
                                        menuId: '5e4d39ec-9df5-4b56-8436-a15df16d3da4',
                                        label: 'Employee Family Patient ID',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/numbering-family-id/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'affd162d-1fe9-4ff9-bcf2-f118ff5ee100',
                                label: 'Employee Data Field Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/employee-data-field-configuration/list',
                                ],
                            },
                            {
                                menuId: '202a4f20-a368-4124-83d7-a85aa074617d',
                                label: 'Master Data Configuration',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'aede8dca-fbaa-4134-8fa3-8fdeb9c7c246',
                                        label: 'Person',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/person/relationship/list',
                                        ],
                                    },
                                    {
                                        menuId: '3fe3ae2f-5fa4-462b-92b8-8ed6f0511b4e',
                                        label: 'Residential',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/data-configuration-master-residence/state-list',
                                        ],
                                    },
                                    {
                                        menuId: 'e282268d-41e7-4401-9325-374f5278732e',
                                        label: 'Education',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/data-configuration-education-master/list',
                                        ],
                                    },
                                    {
                                        menuId: '50b27074-c9db-4316-949c-58da047d025b',
                                        label: 'Language',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/language/list',
                                        ],
                                    },
                                    {
                                        menuId: 'cff3cb56-0250-4e5e-aa94-5c9cc5b26b53',
                                        label: 'Currency',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/data-configuration-currency/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '0c6847c5-22de-4123-94e0-269dbcdd54b6',
                                label: 'Movement Master',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'a3875048-66fc-4a09-ba69-2d6bb42c1362',
                                        label: 'Movement Type',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/movement-type/list',
                                        ],
                                    },
                                    {
                                        menuId: '05ad81d8-5801-4c80-82a5-4507a3df1962',
                                        label: 'Movement Type Setting',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/movement-type-setting/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '2d1a6b94-d968-4424-9f8a-7190d8212a81',
                                label: 'Termination Master',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '6721f917-826f-41b5-9e74-8514ecc65d89',
                                        label: 'Termination Type',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/employee-document-termination/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'a74b44e9-edf9-4b45-a5f0-701056b61116',
                                label: 'Onboarding Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/on-boarding-configuration/list',
                                ],
                            },
                            {
                                menuId: 'fcb61ca9-d534-4880-8d62-e8c14f9c2d1d',
                                label: 'Offboarding Configuration',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/offboarding-configuration/list',
                                ],
                            },
                            {
                                menuId: 'ce646d9e-51f9-4aae-b8c6-1d8bdc48a337',
                                label: 'Employee Reward Master',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/employee-reward-master/list',
                                ],
                            },
                            {
                                menuId: 'be8dcfe6-c6bd-4cac-bce2-2daaf2ed9dd3',
                                label: 'Employee Punishment Master',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'e87a2f0b-2ea3-4ce5-afd5-cabc4bdfed89',
                                        label: 'Punishment Type',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/employee-punishment-master/list',
                                        ],
                                    },
                                    {
                                        menuId: 'ed78d928-7810-49f4-998d-2276e10b932f',
                                        label: 'Punishment Level',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/employee-punishment-master/levels/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '3e443c3c-fd92-4254-b32a-ef5d88c63411',
                                label: 'Employee Asset',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/employee/asset/list',
                                ],
                            },
                            {
                                menuId: 'c2ec18dc-b2ab-4097-8eab-f19f23c330ae',
                                label: 'Employee Document',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/employee-document/document-master/list',
                                ],
                            },
                            {
                                menuId: '7bad0c6b-e976-42a7-a73f-33af59b9461a',
                                label: 'Vendor Master',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/vendor-master/list',
                                ],
                            },
                            {
                                menuId: '50c0deb4-4d1c-4dda-8b8c-c066a76191c2',
                                label: 'Q&A Master',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '6e6d18fc-45a0-4d48-8d9a-b2c9d0645d29',
                                        label: 'Q&A Template',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/qna-template/list',
                                        ],
                                    },
                                    {
                                        menuId: '20961b2d-f807-44c4-8988-36557eaa3832',
                                        label: 'Q&A Template Type',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/interview-qna-template-type/exit-interview/list',
                                        ],
                                    },
                                    {
                                        menuId: 'b532960d-eca9-423a-a0cb-93bfcfd1ec7e',
                                        label: 'Master Measurement',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/measurement-master/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '0789a150-82fb-4d8e-9bd3-c2f7f9d14152',
                                label: 'Action Reason',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/settings/action-reason/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '11f97e6f-ad0f-4167-b1e4-effab60fab54',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'df476a98-c381-4a7f-b860-cc46b8c10b80',
                                label: 'Employee Information',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/activities/employee-information/list',
                                ],
                            },
                            {
                                menuId: 'cd0a3a0f-e204-46be-a43e-aaab479193ca',
                                label: 'New Employee Entry',
                                styleClass: '',
                                routerLink: [
                                    '/admin/workforce/activities/new-employee-entry/list',
                                ],
                            },
                            {
                                menuId: 'eada801c-4f9c-4782-b4a0-cfd8ec501f2d',
                                label: 'Employee Movement',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '504ec6e2-db60-4d6c-9ef1-59ee05db50c8',
                                        label: 'Onboarding',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-movement/on-boarding/list',
                                        ],
                                    },
                                    {
                                        menuId: '996c6236-2b21-43ef-8373-42664cb42230',
                                        label: 'Individual Movement',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-movement/individual-movement/list-publish',
                                        ],
                                    },
                                    {
                                        menuId: '1cd22050-b4b6-469a-9265-1858479a8213',
                                        label: 'Massive Movement',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-movement/massive-movement/list-publish',
                                        ],
                                    },
                                    {
                                        menuId: 'a7ce43c8-748d-44c9-86a0-1d020508a968',
                                        label: 'Acting Job',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-movement/acting-job/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '74198391-5777-452c-af4a-a45631664a71',
                                label: 'Employee Termination',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'a045d21e-fcfb-4247-856f-7693503c5a40',
                                        label: 'Individual Termination',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-termination/individual-termination/list',
                                        ],
                                    },
                                    {
                                        menuId: '2da0867d-ed67-42f1-99b5-7afa70af063a',
                                        label: 'Massive Termination',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/massive-termination/list',
                                        ],
                                    },
                                    {
                                        menuId: '775772cc-0a45-434c-883f-6d7380a51f27',
                                        label: 'Termination Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-termination-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '2a2cd41a-0ace-434b-924f-9eb143fefde8',
                                        label: 'Offboarding',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-termination-offboarding/list',
                                        ],
                                    },
                                    {
                                        menuId: '74c8748d-9d83-41c6-a1d5-6f9aad33cf26',
                                        label: 'Exit Interview',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/exit-interview/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '22e69765-5761-4ec4-a489-edc6e16a27f3',
                                label: 'Employee Review',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '0eef4627-63a2-44d9-8f0c-f324cd551fd7',
                                        label: 'Employee Movement Review',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-review/movement-review/list',
                                        ],
                                    },
                                    {
                                        menuId: '8efdddd1-b887-4401-b54a-41fdf9a0ecad',
                                        label: 'Temporary Employee Review',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-review/temporary-review/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '72d8a476-39a8-48ce-9845-b9f717666297',
                                label: 'Reward Administration',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '8b2fa147-7d81-4f7d-8238-5af310fbed17',
                                        label: 'Reward Received',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/reward-administration/reward-received/list',
                                        ],
                                    },
                                    {
                                        menuId: '433a06e5-04c1-4caa-9c1b-57e82defdaff',
                                        label: 'Reward Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/reward-administration/reward-request/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '0bde4732-1921-410d-ac79-0ccb1258d3d1',
                                label: 'Punishment Administration',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'f8a0ae21-b27b-4c34-992f-5adb316fd977',
                                        label: 'Punishment Received',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/punishment-received/list',
                                        ],
                                    },
                                    {
                                        menuId: '4227775a-7c39-4360-98d3-3680111fbc57',
                                        label: 'Punishment Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/punishment-request/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '8ddfc6be-d2a5-45c8-a781-440a2c0312ac',
                                label: 'Asset Management',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'ea0baddb-d44f-4cbc-9e61-6cae29cb4b73',
                                        label: 'Asset Assignment Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/asset-assignment-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '71448a00-4a21-4f53-82ed-a228f0029cd4',
                                        label: 'Asset Return Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/asset-return-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '62808f51-6b42-4a11-a5db-a3c30f203435',
                                        label: 'Employee Asset',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-asset/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '2f3c3cc3-7924-44c9-a4b3-cadb601a6921',
                                label: 'Document Management',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '3b003c3b-6201-40b1-b680-cffa244bf433',
                                        label: 'Document Entry',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/document-entry-request/list',
                                        ],
                                    },
                                    {
                                        menuId: 'b4227d01-de92-4b5b-94f0-3c9b02203e71',
                                        label: 'Document Return Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/document-return-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '3ee30deb-0a64-4621-9e59-6715ed4e3936',
                                        label: 'Employee Document',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-document/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'fc9f4c62-3442-4449-9588-d4be7dea2ab8',
                                label: 'Employee Letters',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '09beaec9-b8ec-47a2-9ad0-05049aa3d2b3',
                                        label: 'Curriculum Vitae',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/letter/curriculum-vitae',
                                        ],
                                    },
                                    {
                                        menuId: 'd6a561a9-53cb-4ef6-9f69-8367e6939632',
                                        label: 'Reference Letter',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/letter/employee-reference',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'd46866ab-6a8e-4ddf-a748-765e07ced7c2',
                                label: 'Employee Request',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'a4fb60fc-5b18-4b9b-953a-ece9353d08b6',
                                        label: 'Employee Data Update',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-request/employee-data-update/list',
                                        ],
                                    },
                                    {
                                        menuId: '44358afd-99b0-49fe-beaa-31fc82359f27',
                                        label: 'Employee Credential Update',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/activities/employee-credential/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '2adc3b0e-722a-4f22-887c-db6a41c1bae0',
                        label: 'Reports',
                        styleClass: '',
                        items: [
                            {
                                menuId: '2e3d6ca8-d950-4c35-ad73-a5db4d186de3',
                                label: 'Employee',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '6863d642-49cf-45b5-ae90-f58361c581fb',
                                        label: 'Contract Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/reports/contract-expired',
                                        ],
                                    },
                                    {
                                        menuId: '446117c0-be3b-460e-9171-5a20278f0e00',
                                        label: 'Service Period Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/reports/service-period',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '7f4b5c52-0301-4a05-af75-1f432fc8f086',
                                label: 'Movement',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '09ba0127-a5bf-4174-8a98-58bcb54bf258',
                                        label: 'Movement Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/reports/movement',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '87a6c2ef-e544-4e23-88a6-6eec0e958383',
                                label: 'Termination',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '4e48f1e7-1b08-4b42-8db6-8e3af5a80942',
                                        label: 'Termination Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/reports/termination',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '96be3df1-3488-4354-9247-c9f08d49e792',
                                label: 'Reward',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '57825e62-e793-404d-8289-7959ea8cfe9b',
                                        label: 'Award Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/reports/reward',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '407fa681-2598-427f-9670-b8cb8f9c75d9',
                                label: 'Punishment',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'f941313a-5744-4b4b-9d4e-0da99ef0bb85',
                                        label: 'Punishment Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/reports/punishment',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Time',
                icon: 'fas fa-clock',
                routerLink: ['/admin/time'],
                subMenu: [
                    {
                        menuId: '49d70b9c-900d-4cb2-b116-863ee18da6fb',
                        label: 'Setting',
                        styleClass: '',
                        items: [
                            {
                                menuId: '1308f040-a518-48ea-a424-2cfcf5136292',
                                label: 'General Setting',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '08d331d8-c38d-498a-bae2-fc05a116804e',
                                        label: 'Time Policy',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/time-policy/list',
                                        ],
                                    },
                                    {
                                        menuId: 'c60b3b5b-0d87-484a-a567-365951d12df1',
                                        label: 'Leave & Permit Policy',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/leave-permit-policy/detail',
                                        ],
                                    },
                                    {
                                        menuId: 'e84258b9-e1fe-4875-8bec-2e0e857f72d7',
                                        label: 'Color',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/color-master/list',
                                        ],
                                    },
                                    {
                                        menuId: '7dfcb6ae-4151-4d10-8e6c-ab72654ae88c',
                                        label: 'Attendance Code',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/attendance-code/list',
                                        ],
                                    },
                                    {
                                        menuId: 'c3f5e538-e6d5-4e16-9674-ee8edb6679d7',
                                        label: 'Overtime Policy',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/overtime-policy/list',
                                        ],
                                    },
                                    {
                                        menuId: '22eb779b-383e-4a2d-8a73-7bd5f7443900',
                                        label: 'Attendance',
                                        styleClass: '',
                                        items: [
                                            {
                                                menuId: '418dc5ec-559b-4194-be3c-63fff7f95a0c',
                                                label: 'Attendance Location',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/time/settings/attendance/attendance-location/list',
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'df619a47-434e-43c7-bfa8-50c14f05bc85',
                                label: 'Schedule',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '5cd52c44-704b-44ac-9eb6-3422d4eb986b',
                                        label: 'Shift',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/shift/list',
                                        ],
                                    },
                                    {
                                        menuId: '5588ddd2-69ee-4feb-aada-aa7b86012ee8',
                                        label: 'Workgroup',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/workgroup/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'edd55fd0-2a16-444b-b025-9e2a8fbe151d',
                                label: 'Overtime',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '20d1b178-142c-4547-aa68-cd31cdf0e686',
                                        label: 'Overtime Category',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/overtime/category/list',
                                        ],
                                    },
                                    {
                                        menuId: '436c2c4f-eac8-4f85-a5bf-dd30e429772c',
                                        label: 'Overtime Settings',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/overtime/settings/list',
                                        ],
                                    },
                                    {
                                        menuId: 'd2062913-1a4f-480b-a46a-00612df50112',
                                        label: 'Overtime Calculation',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/overtime-calculation/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'd417e3bd-d822-4695-ae8e-e6c57a88ac17',
                                label: 'Leave',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'e75a378b-cd35-40d6-97dd-a654447cfe3f',
                                        label: 'Leave Setting',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/leave-settings/list',
                                        ],
                                    },
                                    {
                                        menuId: '3f4a5ef3-5814-420d-8fd6-69edae76875a',
                                        label: 'Leave Formula',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/leave-formula/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '30e43dd6-1395-4b5f-a226-021a2e58303f',
                                label: 'Daily Wages',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '89a4ab33-6899-4fdc-9cec-65c85da919ff',
                                        label: 'Overtime Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/daily-wages/overtime-allowance/list',
                                        ],
                                    },
                                    {
                                        menuId: 'f6d6f928-9766-4f73-9df8-44091c71b95a',
                                        label: 'Attendance Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/attendance-allowance/list',
                                        ],
                                    },
                                    {
                                        menuId: 'bb907c79-9b7a-4250-8314-bc61c5615ef6',
                                        label: 'Shift Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/daily-wages/shift-allowance/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '387dcd7c-2d88-4372-8ae9-92987adfe8cf',
                                label: 'Permit Setting',
                                styleClass: '',
                                routerLink: [
                                    '/admin/time/settings/permit-settings/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '7a263a87-4c43-44bb-b9e4-7687579c235d',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: '4553bc5d-1303-4585-aadb-fd7b4171abb9',
                                label: 'Schedule',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'a70973b8-1a7b-40b7-a66f-d4160618d4f9',
                                        label: 'Public Holiday Calendar',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/schedule/public-holiday-calendar',
                                        ],
                                    },
                                    {
                                        menuId: 'a7c3f209-61a4-43f0-9b37-b07a31dac48a',
                                        label: 'Employee Calendar',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/schedule/employee-calendar',
                                        ],
                                    },
                                    {
                                        menuId: 'f5f5fd1f-3149-4a75-b7b1-942c05a0cd64',
                                        label: 'Change Schedule',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/change-schedule/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '4e70b2ff-2f72-4a80-8deb-1f4399a5a2aa',
                                label: 'Attendance',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'bb1ca0ac-e234-4185-8bb6-43dec88168f5',
                                        label: 'Raw Attendance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/attendance/raw-attendance/list',
                                        ],
                                    },
                                    {
                                        menuId: '5c40f21e-98a5-4d31-bbf7-70829a11e8ab',
                                        label: 'Timesheet',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/attendance/timesheet/list',
                                        ],
                                    },
                                    {
                                        menuId: 'b837d986-9dc1-424f-8c93-09c2662224a5',
                                        label: 'Process Timesheet',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/process-timesheet/list',
                                        ],
                                    },
                                    {
                                        menuId: '630a8dfd-cac6-46cd-ae3e-9b30dde914dc',
                                        label: 'Attendance Correction',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/attendance-correction/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '6a325fb3-b0fb-4992-b28f-6e3523b0a56a',
                                label: 'Overtime',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'ce222e90-fc00-46d4-b3ee-7a2240fe6b60',
                                        label: 'Overtime Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/overtime-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '01e05fd8-b899-4141-8f28-b3cb17765967',
                                        label: 'Overtime Plan',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/overtime-plan/list',
                                        ],
                                    },
                                    {
                                        menuId: '6b8ba5f6-71e0-4eb1-b415-d189cda4a632',
                                        label: 'Overtime Actual',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/overtime-actual/list',
                                        ],
                                    },
                                    {
                                        menuId: '05a56a1a-f214-47e9-a14b-913e6714a1a9',
                                        label: 'Overtime Allowance Confirmation',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/overtime-allowance-confirmation/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'e52cacfb-a0f4-4f25-9296-d8761fbe7aea',
                                label: 'Leave & Permit',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '35e22057-7e7a-4a89-844f-f1665f4b49e7',
                                        label: 'Generate Leave Balance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/leave-generate-balance/list',
                                        ],
                                    },
                                    {
                                        menuId: '9d1b6c58-1300-420c-9e57-451b2e572f9a',
                                        label: 'Leave Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/leave-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '65688ac4-056a-4259-bd73-bf1e81c42c7c',
                                        label: 'Adjusment Balance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/adjustment-balance/list',
                                        ],
                                    },
                                    {
                                        menuId: '33fd25c7-f0a4-4315-ae88-6fa3d2c4f6e4',
                                        label: 'Permit Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/permit-request/list',
                                        ],
                                    },
                                    {
                                        menuId: 'bd4689ea-7537-4773-8363-12662947f2da',
                                        label: 'Leave Payment',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/leave-payment/list',
                                        ],
                                    },
                                    {
                                        menuId: '51903c8b-8ad2-44b8-94d3-7b3b4c49beb4',
                                        label: 'Employee Balance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/employee-balance/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'e2cd6702-886e-49a2-a5cd-b49cc0ee6ce3',
                                label: 'Daily Wages Process',
                                styleClass: '',
                                routerLink: [
                                    '/admin/time/activities/daily-wages-process/time-calculation',
                                ],
                            },
                            {
                                menuId: 'e52cacfb-a0f4-4f25-9296-d8761fbe7aea',
                                label: 'Switch Schedule',
                                styleClass: '',
                                items: [
                                    {
                                        label: 'Personal Switch Schedule Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/activities/personal-switch-schedule',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '2ce708f5-37e1-4667-a572-1968f6d935ca',
                        label: 'Reports',
                        styleClass: '',
                        items: [
                            {
                                menuId: '2be53cc3-e1fb-4c46-8313-c71e04d87395',
                                label: 'Attendance',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '9becc2de-a3e1-4fb8-b970-d34f68857ea0',
                                        label: 'Late In & Early Out Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/late-in-early-out-report',
                                        ],
                                    },
                                    {
                                        menuId: 'd09eea3d-ceb9-4d80-9143-a2473f590c5e',
                                        label: 'Calculation Late & Earlyout Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/calculation-late-in-early-out-report',
                                        ],
                                    },
                                    {
                                        menuId: '4a4a3410-a2c3-44f5-b7c5-a54f6ebfa298',
                                        label: 'Timesheet Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/timesheet',
                                        ],
                                    },
                                    {
                                        menuId: 'afbbe4c4-d686-4558-ae23-5951edeb35b2',
                                        label: 'Time Summary Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/time-summary',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '9ca1dc54-f5e9-4d38-88c9-21fa573a7708',
                                label: 'Overtime',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '1b973588-010b-4bda-b045-9cd7893133cf',
                                        label: 'Overtime Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/overtime-report',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'e43eaaa3-8bdf-436e-9a91-c1ab113b8abc',
                                label: 'Leave & Permit',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'eb409f35-be27-4c2d-b5fa-a10d7c1e1eb7',
                                        label: 'Permit Request Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/permit-report',
                                        ],
                                    },
                                    {
                                        menuId: '9cfe72bc-42d1-4ffa-9920-52886f18c76e',
                                        label: 'Leave Request Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/leave-report',
                                        ],
                                    },
                                    {
                                        menuId: '6962ac2e-a126-4468-9246-c4161b29623a',
                                        label: 'Leave Payment Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/leave-payment-report',
                                        ],
                                    },
                                    {
                                        menuId: '8f7a7fa2-ea77-4c53-a264-c26374e89ba1',
                                        label: 'Leave & Permit Balance Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/reports/leave-permit-balance-report',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Reimbursement',
                icon: 'fas fa-hand-holding-usd',
                routerLink: ['/admin/reimbursement'],
                subMenu: [
                    {
                        menuId: 'dbecde8e-7cde-42a9-aa57-9db1be2ae162',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '0af26041-910d-4bfd-b134-630a725c2b32',
                                label: 'Reimbursement Policy',
                                styleClass: '',
                                routerLink: [
                                    '/admin/reimbursement/settings/medical-reimbursement-policy',
                                ],
                            },
                            {
                                menuId: '9d98c319-47fd-4602-ae57-bca038214c2d',
                                label: 'Healthcare Institution',
                                styleClass: '',
                                routerLink: [
                                    '/admin/reimbursement/settings/healthcare-institutions/list',
                                ],
                            },
                            {
                                menuId: '6916e315-bb36-488a-b071-b4f6c3486475',
                                label: 'Medical',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'c2d5fede-be0e-4012-b471-c2435a961114',
                                        label: 'Medical Expense',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/settings/medical-expenses/list',
                                        ],
                                    },
                                    {
                                        menuId: '861aee01-4e05-4ff7-beb9-b49fc0d149e9',
                                        label: 'Medical Rule',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/settings/medical-rules/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '328d9be0-86d0-4c28-80dd-5b93afee003f',
                                label: 'Benefit',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'b72fdc4d-852b-4c9b-9e12-97e836ab0ef9',
                                        label: 'Benefit Expense',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/settings/benefit-expenses/list',
                                        ],
                                    },
                                    {
                                        menuId: 'fc189ca7-6fea-47dc-bd39-e34291c00546',
                                        label: 'Benefit Rule',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/settings/benefit-rules/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '6d30ba61-90c8-4421-b690-dc020291963e',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'ff0478d0-5f4a-45b7-8901-fc3ae8fd4a61',
                                label: 'Medical Reimbursement',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '91828a6f-8968-4d77-899b-f507f79c3241',
                                        label: 'Medical Reimbursement Limit',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/activities/medical-limit/list',
                                        ],
                                    },
                                    {
                                        menuId: '1fa13cb1-8953-4a4f-aeed-f8bd768db40b',
                                        label: 'Medical Reimbursement Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/activities/medical-reimburse-request/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '143417ef-ce32-4460-b672-1265475b46e6',
                                label: 'Benefit',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '4d1d1fef-94c5-415b-993e-d6d8930a8a5e',
                                        label: 'Benefit Limit',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/activities/benefit-limit/list',
                                        ],
                                    },
                                    {
                                        menuId: '9028fdcf-fed5-473b-a747-6c9eaa8b5e3c',
                                        label: 'Benefit Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/activities/benefit-request/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '82f99483-49a3-4e6f-9391-63366c406c85',
                                label: 'Reimbursement Payment Process',
                                styleClass: '',
                                routerLink: [
                                    '/admin/reimbursement/activities/reimbursement-payment-process/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'fe65170e-e335-44e9-8c66-e53274942ee4',
                        label: 'Report',
                        styleClass: '',
                        items: [
                            {
                                menuId: '38c20c05-e2fe-42b7-b97b-8d12977796a4',
                                label: 'Medical Report',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '4e55c22b-12eb-43ad-8e93-9dc7b682f2db',
                                        label: 'Detail Medical Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/medical',
                                        ],
                                    },
                                    {
                                        menuId: '58d4527b-92c1-43c9-874b-b25f5f00b207',
                                        label: 'Sumary Medical Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/summary-medical',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '9b5a8d36-5fab-436c-8049-78adaaeb96f5',
                                label: 'Hospital Invoice',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '9c238480-fba7-4430-b466-c78576c82d40',
                                        label: 'Hospital Garanty Letter',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/hospital-garanty-letter',
                                        ],
                                    },
                                    {
                                        menuId: '5db841d1-f4a3-4315-8116-e00524e702d3',
                                        label: 'Hospital Invoice Recapitulation Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/hospital-invoice-recapitulation',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '4c6b361f-7bb0-4360-850c-b198f2dc6a26',
                                label: 'Donation',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '648e2596-2b9c-4be7-bf01-f15977a51512',
                                        label: 'Detail Donation Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/donation',
                                        ],
                                    },
                                    {
                                        menuId: 'f78b9492-a4c5-4779-8dcc-eb1464839330',
                                        label: 'Sumary Education Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/summary-education',
                                        ],
                                    },
                                    {
                                        menuId: 'edb59e1f-5c89-40dc-865a-dc99c31a088c',
                                        label: 'Sumary Recreation Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/summary-recreation',
                                        ],
                                    },
                                    {
                                        menuId: '595fa01b-a296-425b-b387-d8bc55e8fbac',
                                        label: 'Donation Memorandum',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/donation-memorandum',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '0099ee2a-c8c5-4acb-b57b-3445d9481fb5',
                                label: 'Business Trip Report',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '3efaf977-c725-45ac-9437-e580a5bb6629',
                                        label: 'Business Trip Cost Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/business-trip',
                                        ],
                                    },
                                    {
                                        menuId: 'dfce6c95-164d-40d3-a111-91ca10f79a11',
                                        label: 'Sumary SPPD Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/reimbursement/reports/summary-sppd',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'd1d9bd2e-e6d5-43e1-84ba-d8f2bab9b8d2',
                                label: 'Hospital Invoice Report',
                                styleClass: '',
                                routerLink: [
                                    '/admin/reimbursement/reports/hospital-invoice',
                                ],
                            },
                            {
                                menuId: '29b2e2b2-9d88-4fb4-93c9-18a0e01408a8',
                                label: 'Service Periode Report',
                                styleClass: '',
                                routerLink: [
                                    '/admin/reimbursement/reports/service-periode',
                                ],
                            },
                            {
                                menuId: '464d54f2-d3f6-48bf-bc49-ec4d55a8b1d1',
                                label: 'Report to BOD',
                                styleClass: '',
                                routerLink: [
                                    '/admin/reimbursement/reports/to-bod',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Payroll',
                icon: 'fas fa-money-bill',
                routerLink: ['/admin/payroll'],
                subMenu: [
                    {
                        menuId: '98f36c8f-e1fc-49e5-89dc-f56c6dc8a6e1',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '8b67ba21-ac98-4767-b72e-9c7ad3086436',
                                label: 'Payroll Policy',
                                styleClass: '',
                                routerLink: [
                                    '/admin/payroll/settings/payroll-policy',
                                ],
                            },
                            {
                                menuId: '44b2c414-95f0-439d-a301-dbd9a35c885e',
                                label: 'Bank',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'c84cd198-2495-4c05-a32b-1a9b10209468',
                                        label: 'Company Bank',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/bank/company-bank/list',
                                        ],
                                    },
                                    {
                                        menuId: '5eebda53-7227-4b50-9aa8-46d7f1fb7ef1',
                                        label: 'Employee Bank',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/employee-bank/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'b2a087b6-412a-4fbf-afc5-92b61fb9ee3e',
                                label: 'Payroll',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'b6916bcb-172e-4d91-b2a3-95f0a7d72b0d',
                                        label: 'Payroll Component',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/payroll-component/list',
                                        ],
                                    },
                                    {
                                        menuId: '7237546d-9683-4ed3-94ce-3d1f9d00a08b',
                                        label: 'Payroll Group',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/payroll-group/list',
                                        ],
                                    },
                                    {
                                        menuId: 'b026fc68-f620-4c94-8ee4-b6677091d37a',
                                        label: 'Retroactive Component Mapping',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/retroactive-mapping/list',
                                        ],
                                    },
                                    {
                                        menuId: 'ce2ece32-b1aa-41c8-b1e9-b77208ae5fa7',
                                        label: 'Prorate Component',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/prorate-component/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'dac4e985-b711-444d-8632-e096a0f2efe7',
                                label: 'Component Calculation',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'cd319605-8ba9-475e-816d-307af397ad20',
                                        label: 'Criteria Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/criteria-allowance/list',
                                        ],
                                    },
                                    {
                                        menuId: '7b15436a-164c-459c-bf5f-6a6327b06192',
                                        label: 'Schedule Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/schedule-allowance/list',
                                        ],
                                    },
                                    {
                                        menuId: '810d5da2-93ca-4783-ac95-a68de6d46350',
                                        label: 'Service Period Setup',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/service-period-setup/list',
                                        ],
                                    },
                                    {
                                        menuId: 'ce1107af-f60e-4cb5-8831-354c60458eda',
                                        label: 'Service Period Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/service-period-allowance/list',
                                        ],
                                    },
                                    {
                                        menuId: '17723419-5bda-4085-bdff-c021be7185ab',
                                        label: 'Formula Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/formula-allowance/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '8241f723-3e50-448d-8845-833cbaf5c93b',
                                label: 'Bonus & Allowance',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '0adab42d-d9b3-40d0-8a63-db854f2e77fb',
                                        label: 'Performance Index Allowance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/performance-index-allowance/list',
                                        ],
                                    },
                                    {
                                        menuId: 'feaac5bd-23b6-48e6-9433-10d61be17f20',
                                        label: 'Bonus & Allowance Setting',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/bonus-and-allowance-setting/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '13b735ac-2f3f-4f8d-8d0f-71a27a5ba7d6',
                                label: 'Severance Component',
                                styleClass: '',
                                routerLink: [
                                    '/admin/payroll/settings/severance-component/list',
                                ],
                            },
                            {
                                menuId: 'a7076d89-6858-4421-a5a7-a72d329b1624',
                                label: 'Tax',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '4ebb836c-aa5a-4db4-8fa5-fecd7c3f7000',
                                        label: 'Tax Location',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/tax-location/list',
                                        ],
                                    },
                                    {
                                        menuId: 'e63e69bc-8b4a-43b7-af7c-bf0a45abba12',
                                        label: 'Company Tax',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/tax/company-tax/list',
                                        ],
                                    },
                                    {
                                        menuId: '4b8db7c4-09ed-44ce-a05c-7e6d529e5951',
                                        label: 'Tax Group',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/tax-group/list',
                                        ],
                                    },
                                    {
                                        menuId: '4e5a1200-47e8-49df-b378-251e78f85c30',
                                        label: 'Tax Setup',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/tax-setup/list',
                                        ],
                                    },
                                    {
                                        menuId: '90741da6-d3a6-4b8a-b222-2ea3e0ed6033',
                                        label: 'Tax Effective Rate Mapping',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/tax-rate/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '7e7ac681-243e-429d-b076-61ed5a06d3e6',
                                label: 'BPJS',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'c5415ed2-3d00-4569-ad99-999f1b3133ad',
                                        label: 'BPJS Office',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/bpjs-office/list-ketenagakerjaan',
                                        ],
                                    },
                                    {
                                        menuId: '86268dc1-4d77-48fb-b775-8126aecf94be',
                                        label: 'BPJS KS Company',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/bpjs-ks-company/list',
                                        ],
                                    },
                                    {
                                        menuId: '159ba7e2-b24d-4325-9c0e-7f088b4056ac',
                                        label: 'BPJS TK Company',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/bpjs-tk-company/list',
                                        ],
                                    },
                                    {
                                        menuId: 'be096ac8-20a3-491e-adef-41c69aaa9351',
                                        label: 'BPJS Group',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/bpjs-group/kesehatan/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'd42392b1-db91-46cd-ba77-d5a8c9169880',
                                label: 'Loan',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'ce64adc8-bee1-4bdd-b870-1bcc826d0d07',
                                        label: 'Loan Rules Configuration',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/loan-rules-configuration/list',
                                        ],
                                    },
                                    {
                                        menuId: '28cb7c5f-6c46-477e-a820-c2d47588c38d',
                                        label: 'Loan Whitelist Reason',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/loan-whitelists-reason/list',
                                        ],
                                    },
                                    {
                                        menuId: '042d1529-cd0f-4f1d-a333-0b5de2db26f1',
                                        label: 'Loan Type',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/settings/loan/loan-type/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '36edc705-60cd-413e-8202-2c3319b3ab75',
                                label: 'Employee Minimum Wage (UMK)',
                                styleClass: '',
                                routerLink: [
                                    '/admin/payroll/settings/employee-minimum-wage/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '166ed5e1-eda1-4b1a-a1fe-2f31833c7df0',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: '527b24b9-3130-4612-8239-44fc586ccc61',
                                label: 'Salary Decision',
                                styleClass: '',
                                routerLink: [
                                    '/admin/payroll/activities/salary-decision/list',
                                ],
                            },
                            {
                                menuId: '6ad71e01-43c7-41fd-9865-f4737858c932',
                                label: 'Schedule Allowance Process',
                                styleClass: '',
                                routerLink: [
                                    '/admin/payroll/activities/schedule-allowance-process/list',
                                ],
                            },
                            {
                                menuId: '2f3c7fcf-46bd-4ce4-9fa0-6ea6f5c8f135',
                                label: 'Monthly Process',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '144f3803-8cba-4537-981e-a48a2fffefad',
                                        label: 'Monthly Editable',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/monthly-editable/list',
                                        ],
                                    },
                                    {
                                        menuId: 'ed054939-560b-4580-bcdd-8209e1f1b533',
                                        label: 'Payroll Process',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/monthly-process/payroll-process/list',
                                        ],
                                    },
                                    {
                                        menuId: '58004b1b-a9e6-4392-bda0-cb9d33aa1829',
                                        label: 'Payroll Payment Process',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/monthly-process/payroll-payment-process/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'c7089132-a427-4021-8cbc-5be823a29fc0',
                                label: 'Bonus & Allowance Process',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '01fb3be4-5ff2-4001-bc0c-7a5d320923d2',
                                        label: 'Bonus & Allowance Editable',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/bonus-and-allowance-editable/employee/list',
                                        ],
                                    },
                                    {
                                        menuId: 'ba182c6f-c322-49d8-9dac-16260aee1010',
                                        label: 'Bonus & Allowance Process',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/bonus-and-allowance-process/list',
                                        ],
                                    },
                                    {
                                        menuId: '7de16cbf-5345-4d59-ba63-1f1550aaf75c',
                                        label: 'Bonus & Allowance Payment Process',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/bonus-and-allowance-payment-process/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '4ab1bc40-e4bc-4c56-ae14-5a7bc9a1c4e0',
                                label: 'Retroactive Process',
                                styleClass: '',
                                routerLink: [
                                    '/admin/payroll/activities/retroactive-process/list',
                                ],
                            },
                            {
                                menuId: 'c6108663-daed-46d0-9c27-a952a085ab57',
                                label: 'BPJS',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'f244754e-1669-4b1c-8a7d-d14708bcaa6c',
                                        label: 'BPJS TK Employee Administration',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/bpjs-tk-employee-adm/list',
                                        ],
                                    },
                                    {
                                        menuId: '43a430f1-3aef-472d-9bc0-93996776bcc6',
                                        label: 'BPJS KS Employee Administration',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/bpjs-ks-employee-adm/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'bd720dde-2d39-4f62-b1d8-1ae1973590a8',
                                label: 'Severance Process',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'e225290f-5b72-4226-a2aa-d44528ed9ea0',
                                        label: 'Severance Process Editable',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/severance-process-editable/list',
                                        ],
                                    },
                                    {
                                        menuId: '6270d996-d376-4be8-ac52-49a954292d4f',
                                        label: 'Severance Process',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/severance-process/list',
                                        ],
                                    },
                                    {
                                        menuId: 'ab065036-08bd-461c-a0f0-e759a7b7a822',
                                        label: 'Severance Payment Process',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/severance-payment-process/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '24680539-7a6f-4b35-b093-5d2f6d6ca564',
                                label: 'Tax',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '738b5cc0-48b3-4b6d-a79e-9d58b1a749b7',
                                        label: 'Tax Administration Employee',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/tax-employee-administration/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'abe31e74-2bbf-4575-b564-7ea8e2046076',
                                label: 'Loan',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '0676b94b-4380-4a37-ad67-947b81b19049',
                                        label: 'Loan Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/loan-request/list',
                                        ],
                                    },
                                    {
                                        menuId: '7627efee-dde1-4965-b796-01263c08cfbb',
                                        label: 'Loan Rescheduling',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/loan-rescheduling/list',
                                        ],
                                    },
                                    {
                                        menuId: '0818f174-50e4-4c54-9c91-33cbdda60cbf',
                                        label: 'Loan Installments',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/loan-installments/list-payroll',
                                        ],
                                    },
                                    {
                                        menuId: 'd6e3a446-104d-4f28-91e2-0e895928e0a8',
                                        label: 'Loan Whitelist',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/activities/loan-whitelist/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'f3146fba-0a76-4a45-93d2-75039af8f4ca',
                        label: 'Reports',
                        styleClass: '',
                        items: [
                            {
                                menuId: '6b983be3-7229-4fd7-bfbf-54aff940153a',
                                label: 'Payroll',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '92f7bd08-7cef-4935-ac3f-1e76ae89e2ac',
                                        label: 'Payroll Detail',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/reports/payroll-details',
                                        ],
                                    },
                                    {
                                        menuId: 'bfd07ff2-998a-4d83-a92d-0a238a3c338c',
                                        label: 'Payroll Summary',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/reports/payroll-summary',
                                        ],
                                    },
                                    {
                                        menuId: 'fb018631-3335-4b57-83fd-1ed430c2c545',
                                        label: 'Accumulation Payroll Detail',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/reports/accumulation-payroll',
                                        ],
                                    },
                                    {
                                        menuId: 'fce14b9f-98a5-4f57-b385-85280c04b5f2',
                                        label: 'Year To Date',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/reports/year-to-date',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'df4c349f-bfa3-4f89-801a-c703014c8c69',
                                label: 'Salary Slip',
                                styleClass: '',
                                routerLink: [
                                    '/admin/payroll/reports/salary-slip',
                                ],
                            },
                            {
                                menuId: '123bb2fb-d2f6-47f1-9dec-61960ef751aa',
                                label: 'Loan',
                                styleClass: '',
                                routerLink: ['/admin/payroll/reports/loan'],
                            },
                            {
                                menuId: '3afb2b18-4726-4d33-890d-d33d650a040c',
                                label: 'BPJS',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '47af95ee-31ae-418d-8825-1feb9314b94f',
                                        label: 'BPJS Report',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/reports/bpjs',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'c954da79-12b7-4220-b151-e217b7dc2af2',
                                label: 'Tax',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'ed0bb1d0-750f-41fe-bd67-777a95743a4d',
                                        label: 'E-Bupot Pph 21',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/reports/e-bupot-pph-21',
                                        ],
                                    },
                                    {
                                        menuId: 'cf1e7bf7-e5b7-4bed-bc90-53f60c8869ad',
                                        label: 'E-Bupot Pph 21 A1',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/payroll/reports/e-bupot-pph-21-a1',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Recruitment',
                icon: 'fas fa-user-plus',
                routerLink: ['/admin/recruitment'],
                subMenu: [
                    {
                        menuId: '466b9238-9696-47c7-8514-d7ac52be3883',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '0edc1fbb-419b-4808-8b13-fc978bd951ac',
                                label: 'Activity Stage',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/activity-stage/list',
                                ],
                            },
                            {
                                menuId: 'd76f89b1-6d7f-4a4c-a3dd-ed7c830e2ca5',
                                label: 'Template Stage',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/template-stage/list',
                                ],
                            },
                            {
                                menuId: '393bb303-da73-4404-97a6-10b5fe963068',
                                label: 'Replaced Caused',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/replaced-caused/list',
                                ],
                            },
                            {
                                menuId: '5dc3ee15-8365-471f-81db-50ac9c0eac7c',
                                label: 'Career Path',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/career-path/list',
                                ],
                            },
                            {
                                menuId: 'df632eb2-792a-464b-a8c8-eba16af9b4c8',
                                label: 'Master Tag',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/master-tag/list',
                                ],
                            },
                            {
                                menuId: 'fd2d91ee-b353-47db-bdf8-97261c10d5c6',
                                label: 'Job Vacancy Reason',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/job-vacancy-reason/list',
                                ],
                            },
                            {
                                menuId: 'fc8c344f-18ba-4981-8262-28fb74c02e7b',
                                label: 'Job Specification',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/job-specification/list',
                                ],
                            },
                            {
                                menuId: '3763b631-ee96-42aa-a20b-f6bda7337da9',
                                label: 'Q&A',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/qna/list',
                                ],
                            },
                            {
                                menuId: 'c21a3d6a-05c3-4012-bdca-ae6b270436aa',
                                label: 'Job Vacancy Template',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/settings/job-vacancy-template/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '296bb013-c2ee-4393-bf9f-0ba474ebc9d8',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: '803565ac-ddb6-4930-bec7-be6a4a8494a7',
                                label: 'Employee Request',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/activities/employee-request/list',
                                ],
                            },
                            {
                                menuId: 'a5ad6a90-02b2-48fb-96b1-e03af1d57c20',
                                label: 'Job Vacancy',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/activities/job-vacancy/list',
                                ],
                            },
                            {
                                menuId: 'f52864ce-88d8-46c3-a0c0-82153e4df24f',
                                label: 'Candidate',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/activities/candidate/list',
                                ],
                            },
                            {
                                menuId: '20413553-498b-4495-92a7-f4dc9aed4ebd',
                                label: 'Selection Process',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/activities/event/list',
                                ],
                            },
                            {
                                menuId: 'd85981eb-0881-4f41-8b37-da2bcb5b1ef1',
                                label: 'Offering',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/activities/offering/list',
                                ],
                            },
                            {
                                menuId: '4ee6ad19-8314-4393-9a17-73d2afb9b9b4',
                                label: 'Hiring',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/activities/hiring/list',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '7f877623-272f-439b-a696-ffe653201500',
                        label: 'Reports',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'e073791a-61c0-4d61-af4f-8ab23a1da703',
                                label: 'Candidate Report',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/reports/candidate-report',
                                ],
                            },
                            {
                                menuId: '9e7a952f-da6b-46fb-a426-cba6622d7a1a',
                                label: 'Recruitment Progress',
                                styleClass: '',
                                routerLink: [
                                    '/admin/recruitment/reports/recruitment-progress',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Competency',
                icon: 'fas fa-chart-simple',
                routerLink: ['/admin/competency'],
                subMenu: [
                    {
                        menuId: 'a0ee25b2-b7e0-4751-9dbf-f6440b32e1da',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '90e772d0-a7e8-436b-ae58-2eb15bbfb902',
                                label: 'System Administrator',
                                styleClass: '',
                                routerLink: [
                                    '/admin/competency/settings/configurations/list',
                                ],
                            },
                            {
                                menuId: '51ac9821-9fb0-49ac-be15-5defeaf0e003',
                                label: 'Domain',
                                styleClass: '',
                                routerLink: [
                                    '/admin/competency/settings/domain/list',
                                ],
                            },
                            {
                                menuId: '20207336-28c7-4251-954f-e8418a205e97',
                                label: 'Proficiency Level',
                                styleClass: '',
                                routerLink: [
                                    '/admin/competency/settings/proficiency/list',
                                ],
                            },
                            {
                                menuId: '3b51090b-dc50-4fc7-a50e-453b1940fa86',
                                label: 'Competency List',
                                styleClass: '',
                                routerLink: [
                                    '/admin/competency/settings/competencies/list',
                                ],
                            },
                            {
                                menuId: '5f80250c-3fe7-4a97-82c9-ffb85905ad8c',
                                label: 'Competency Aspect',
                                styleClass: '',
                                routerLink: [
                                    '/admin/competency/settings/competency-aspect/list',
                                ],
                            },
                            {
                                menuId: '01d50a5e-8ffb-492d-95d1-760b942608f0',
                                label: 'Indicator/Behavior',
                                styleClass: '',
                                routerLink: [
                                    '/admin/competency/settings/indicator/list',
                                ],
                            },
                            {
                                menuId: '41a1d956-70d2-4009-ade0-0646ffdb7782',
                                label: 'Competency Assignment',
                                styleClass: '',
                                routerLink: [
                                    '/admin/competency/settings/competency-assignment/list',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Performance',
                icon: 'fas fa-chart-line',
                routerLink: ['/admin/performance'],
                subMenu: [
                    {
                        menuId: '233903de-47f6-4c49-b8ee-adaf78f11bd0',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '3c724ad7-6050-4af2-96fe-206c999a97f4',
                                label: 'General Setting',
                                styleClass: '',
                                routerLink: [
                                    '/admin/performance/settings/configurations/list',
                                ],
                            },
                            {
                                menuId: '8e0f82cd-0a20-405f-a053-a33f967a4f14',
                                label: 'Period',
                                styleClass: '',
                                routerLink: [
                                    '/admin/performance/settings/parameter/list',
                                ],
                            },
                            {
                                menuId: 'f16d96c6-7e91-47c9-a6b4-38096fafba5b',
                                label: 'Measurements',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'da67b34f-86c6-4b3b-946f-cd0018bbb386',
                                        label: 'P3JJ Value',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/settings/masterp3jj/list',
                                        ],
                                    },
                                    {
                                        menuId: '4a4b9e18-3d72-47c9-8aba-9eb99de30056',
                                        label: 'Score Measurement',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/settings/score-measurement/data-scoring/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'ff169ea3-9f54-4626-9943-d883eaa28d49',
                                label: 'Result',
                                styleClass: '',
                                routerLink: [
                                    '/admin/performance/settings/result/list',
                                ],
                            },
                            {
                                menuId: '35ac1225-84b3-4f98-91fa-3e67abc09171',
                                label: 'Form',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '77c9df37-2b0b-4b06-8133-fcd89401e029',
                                        label: 'Form Template',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/settings/form-template/list',
                                        ],
                                    },
                                    {
                                        menuId: 'dd0d14ab-4c32-442b-b753-1c01ca0371ed',
                                        label: 'Assign Form',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/settings/assign-form/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'fc004d84-0cbf-413d-8bf4-ecc3102cf314',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'b5b566ef-5ff6-4dc8-b07c-8a597d68eb9a',
                                label: 'Competency Review',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'a5f11323-bf33-44c3-9744-4f14a54988af',
                                        label: 'Form Review',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/activities/form-review/list',
                                        ],
                                    },
                                    {
                                        menuId: 'ede2d474-3377-41d1-9347-0fd5c36991f2',
                                        label: 'Calibration Review',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/activities/calibration-review/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'fcdb2c73-0383-4eea-a51e-967e1c91f25b',
                                label: 'Review Data Process',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'bb6346e5-e176-4091-b6d6-be7902984eb8',
                                        label: 'Olah Data Form 149',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/activities/form-149-process/list',
                                        ],
                                    },
                                    {
                                        menuId: 'c45931bc-8e66-4a66-b10a-0754f2b52cce',
                                        label: 'Olah Data Form 17',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/activities/form-17-process/list',
                                        ],
                                    },
                                    {
                                        menuId: 'c6d43853-5909-4a07-a95b-899861ff06aa',
                                        label: 'Olah Data Form Managerial',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/activities/form-manajerial/list',
                                        ],
                                    },
                                    {
                                        menuId: 'b9038c55-548d-482d-892f-a66cfc3a8cd4',
                                        label: 'Olah Data Form Non-Managerial',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/performance/activities/form-non-manajerial/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '3bd65c17-60c0-4e07-a385-be1db69553fa',
                        label: 'Result',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'bf0ca6fb-c3dc-4c0d-a996-81967e62b458',
                                label: 'Individual Assessment',
                                styleClass: '',
                                routerLink: [
                                    '/admin/performance/result/individual-assesment-result/list',
                                ],
                            },
                            {
                                menuId: '652a6fb8-ccfb-4bb8-800a-18fbd56bfca0',
                                label: 'Organization Assessment',
                                styleClass: '',
                                routerLink: [
                                    '/admin/performance/result/organization-assesment-result/list-indicator',
                                ],
                            },
                        ],
                    },
                    {
                        menuId: 'c1cc9de8-88c5-4aba-b71d-985e6b643d50',
                        label: 'Report',
                        styleClass: '',
                        items: [
                            {
                                menuId: 'afd7e5ac-dbcf-4cdd-9e5c-3948f7035541',
                                label: 'Competency Review Result',
                                styleClass: '',
                                routerLink: [
                                    '/admin/performance/reports/competency-review',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Loan',
                icon: 'fas fa-credit-card',
                routerLink: ['/admin/loan'],
                subMenu: [],
            },
        ],
    },
    {
        items: [
            {
                label: 'Carrer Path',
                icon: 'fas fa-map-signs',
                routerLink: ['/admin/carrer-path'],
                subMenu: [],
            },
        ],
    },
    {
        items: [
            {
                label: 'Training',
                icon: 'fas fa-graduation-cap',
                routerLink: ['/admin/lnd'],
                subMenu: [
                    {
                        menuId: 'c57ee081-f43c-47cc-bb1b-75391e7f0804',
                        label: 'Settings',
                        styleClass: '',
                        items: [
                            {
                                menuId: '8461de23-7bed-4913-b2bf-6adf4535a45d',
                                label: 'Learning Period',
                                styleClass: '',
                                routerLink: [
                                    '/admin/lnd/settings/training-period/list',
                                ],
                            },
                            {
                                menuId: 'e7083057-24a4-41d0-95f9-34800c0f55ed',
                                label: 'Learning List',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '5610322d-6aa3-4d3b-9603-62aa75dc0843',
                                        label: 'Category',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/settings/training-list/training-group/list',
                                        ],
                                    },
                                    {
                                        menuId: 'b34bebc3-248d-485f-b577-15bac973204b',
                                        label: 'List',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/settings/training-list/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '9066081a-4970-4447-9633-36e43d637873',
                                label: 'Topic',
                                styleClass: '',
                                routerLink: ['/admin/lnd/settings/topic/list'],
                            },
                            {
                                menuId: 'f157b8a2-6866-4996-98e4-5306f9fb3654',
                                label: 'Course',
                                styleClass: '',
                                routerLink: ['/admin/lnd/settings/course/list'],
                            },
                            {
                                menuId: 'a587c893-a980-4a02-8a2a-c8f8629eca31',
                                label: 'Provider',
                                styleClass: '',
                                routerLink: [
                                    '/admin/lnd/settings/provider/list',
                                ],
                            },
                            {
                                menuId: '880411f4-9347-4247-ac0f-eb4bf44cf151',
                                label: 'Instructor',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: '7da6d92e-4d72-4eb2-9264-3217ca9851d7',
                                        label: 'External Instructor',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/settings/external-instructor/list',
                                        ],
                                    },
                                    {
                                        menuId: '52519b30-c09a-421c-aa31-595459b3fccc',
                                        label: 'Internal Instructor',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/settings/internal-instructor/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'f48ee87c-fb10-4d12-947b-384e2d90e3bd',
                                label: 'Learning PIC',
                                styleClass: '',
                                routerLink: [
                                    '/admin/lnd/settings/learning-pic/list',
                                ],
                            },
                            {
                                menuId: 'ee6a71cd-4f61-4e1a-a206-20ef45c948ce',
                                label: 'Venue',
                                styleClass: '',
                                routerLink: ['/admin/lnd/settings/venue/list'],
                            },
                            {
                                menuId: '6958fa4e-f266-4406-a76f-bad794d4c67a',
                                label: 'Test Master',
                                styleClass: '',
                                routerLink: [
                                    '/admin/lnd/settings/test-form-template/list',
                                ],
                            },
                            {
                                menuId: '4534b228-73bb-4f13-892e-f1c2faf4b9c4',
                                label: 'Evaluation Form',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'ba81888b-f937-49e4-8099-ad55d7a0e6af',
                                        label: 'Score Value',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/settings/score-value/list',
                                        ],
                                    },
                                    {
                                        menuId: '406ffa98-617e-45d7-b0de-3f64e6608704',
                                        label: 'Evaluation Form',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/settings/evaluation-form/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        menuId: '8afb15e2-f1d6-45ed-bb6e-de46c44efbd7',
                        label: 'Activities',
                        styleClass: '',
                        items: [
                            {
                                menuId: '2ead250e-33d7-497e-a513-2cba06a4ac56',
                                label: 'Learning Plan',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'a7b6813b-4ae6-48ed-bbf5-591d24a09994',
                                        label: 'Learning Plan',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/activities/learning-plan/list',
                                        ],
                                    },
                                    {
                                        menuId: '3134ae03-344d-4979-abb8-c7020f316812',
                                        label: 'Learning Event',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/activities/learning-event/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: '6d0ff2c6-e09e-4936-9eb9-b7ff5c1b8849',
                                label: 'Learning Request',
                                styleClass: '',
                                items: [
                                    {
                                        menuId: 'de8b06cc-7fa2-4c8f-bba7-295001925ac2',
                                        label: 'Learning Plan Request',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/lnd/activities/learning-request/learning-plan-request/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                menuId: 'b3e4aa68-e19f-40db-bb38-b27fcb592e1e',
                                label: 'Learning Monitoring',
                                styleClass: '',
                                routerLink: ['/admin/lnd/learning-monitoring'],
                            },
                            {
                                menuId: 'c062adbe-6733-4446-b4b5-97a9fa6e82e0',
                                label: 'Learning Evaluation',
                                styleClass: '',
                                routerLink: ['/admin/lnd/learning-evaluation'],
                            },
                        ],
                    },
                    {
                        menuId: 'e7a8065c-c52f-448f-96c2-466a196bfc0d',
                        label: 'Report',
                        styleClass: '',
                        items: [
                            {
                                menuId: '0511abd7-d67d-4c43-9d9a-457b96aa4db3',
                                label: 'Learning Summary',
                                styleClass: '',
                                routerLink: ['/admin/lnd/learning-summary'],
                            },
                            {
                                menuId: 'cadec046-7b41-43d3-b0f8-cbe2811c55e0',
                                label: 'Learning Individual',
                                styleClass: '',
                                routerLink: ['/admin/lnd/learning-individual'],
                            },
                            {
                                menuId: 'd428f5ef-a3c9-430a-a3d4-0962364dc549',
                                label: 'Learning Plan',
                                styleClass: '',
                                routerLink: ['/admin/lnd/learning-plan'],
                            },
                            {
                                menuId: 'e9cb918b-d1bf-4f25-962f-d4ea2fa5d421',
                                label: 'Learning Plan & Actual',
                                styleClass: '',
                                routerLink: [
                                    '/admin/lnd/learning-plan-&-actual',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        items: [
            {
                label: 'Log History',
                icon: 'fa-solid fa-clock-rotate-left',
                routerLink: ['/admin/log-history'],
                subMenu: [
                    {
                        menuId: '21f94e90-812d-4890-a3e2-ac0c6d4617cf',
                        label: 'Log History',
                        styleClass: '',
                        routerLink: ['/admin/log-history'],
                    },
                ],
            },
        ],
    },
];
