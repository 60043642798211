import {
    Input,
    OnInit,
    Output,
    Component,
    OnChanges,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';

@Component({
    styles: [
        `
            .disabled {
                color: #9f9f9f;
            }

            .invalid {
                color: #d85454;
            }

            .pristine {
                color: #9f9f9f;
            }

            .valid {
                color: #54b486;
            }
        `,
    ],
    selector: 'l-user-password-info',
    template: `
        <div class="flex flex-column" style="gap: 8px;">
            <div [ngClass]="textColorInvalidOrInvalidStyle(minCharacters)">
                <i
                    class="fa-solid"
                    [ngClass]="iconValidOrInvalidStyle(minCharacters)"
                ></i>
                <span style="margin-left: 10px;"
                    >{{ 'min.' | translate }} 8
                    {{ 'characters' | translate }}</span
                >
            </div>
            <div
                [ngClass]="
                    textColorInvalidOrInvalidStyle(consistsOfLettersAndNumbers)
                "
            >
                <i
                    class="fa-solid"
                    [ngClass]="
                        iconValidOrInvalidStyle(consistsOfLettersAndNumbers)
                    "
                ></i>
                <span style="margin-left: 10px;">{{
                    'consistsOfLetters&Numbers' | translate
                }}</span>
            </div>
            <div
                [ngClass]="
                    textColorInvalidOrInvalidStyle(consistsOfSpecialCharacter)
                "
            >
                <i
                    class="fa-solid"
                    [ngClass]="
                        iconValidOrInvalidStyle(consistsOfSpecialCharacter)
                    "
                ></i>
                <span style="margin-left: 10px;">{{
                    'consistsOfSpecialCharacter' | translate
                }}</span>
            </div>
            <div
                [ngClass]="
                    textColorInvalidOrInvalidStyle(consistsOfCapitalLetter)
                "
            >
                <i
                    class="fa-solid"
                    [ngClass]="iconValidOrInvalidStyle(consistsOfCapitalLetter)"
                ></i>
                <span style="margin-left: 10px;">{{
                    'consistsOfCapitalLetter' | translate
                }}</span>
            </div>
            <div
                [ngClass]="
                    textColorInvalidOrInvalidStyle(consistsOfLowerCaseLetter)
                "
            >
                <i
                    class="fa-solid"
                    [ngClass]="iconValidOrInvalidStyle(consistsOfLowerCaseLetter)"
                ></i>
                <span style="margin-left: 10px;">{{
                    'consistsOfLowerCaseLetter' | translate
                }}</span>
            </div>
        </div>
    `,
})
export class UserPasswordInfoComponent implements OnInit, OnChanges {
    @Input()
    public value: string | null = null;

    @Input()
    public isSubmitted: boolean = false;

    @Output('valid')
    private readonly _valid: EventEmitter<boolean> = new EventEmitter<boolean>(
        false
    );

    public minCharacters!: boolean | undefined;
    public consistsOfCapitalLetter!: boolean | undefined;
    public consistsOfLowerCaseLetter!: boolean | undefined;
    public consistsOfSpecialCharacter!: boolean | undefined;
    public consistsOfLettersAndNumbers!: boolean | undefined;

    private _isValid() {
        return (
            this.minCharacters &&
            this.consistsOfCapitalLetter &&
            this.consistsOfSpecialCharacter &&
            this.consistsOfLettersAndNumbers &&
            this.consistsOfLowerCaseLetter
        );
    }

    private _setValidators(value: string): void {
        // if (value.length >= 8) {
        //     this.minCharacters = true;
        // } else {
        //     this.minCharacters = false;
        // }

        // if (value.match(/[\w]/) && value.match(/[\d]/)) {
        //     this.consistsOfLettersAndNumbers = true;
        // } else {
        //     this.consistsOfLettersAndNumbers = false;
        // }

        // if (value.match(/[!@#$%^&*(),.?":{}|<>]/)) {
        //     this.consistsOfSpecialCharacter = true;
        // } else {
        //     this.consistsOfSpecialCharacter = false;
        // }

        // if (value.match(/[/[A-Z]/)) {
        //     this.consistsOfCapitalLetter = true;
        // } else {
        //     this.consistsOfCapitalLetter = false;
        // }

        this.minCharacters = value.length >= 8;

        this.consistsOfLettersAndNumbers =
            /[a-zA-Z]/.test(value) && /\d/.test(value);

        this.consistsOfSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

        this.consistsOfCapitalLetter = /[A-Z]/.test(value);

        this.consistsOfLowerCaseLetter = /[a-z]/.test(value);
    }

    public textColorInvalidOrInvalidStyle(value: boolean): any {
        if ([null, undefined].includes(value)) {
            return {
                pristine: true,
            };
        }

        return {
            invalid: this.isSubmitted && !value,
            disabled: !this.isSubmitted && !value,
        };
    }

    public iconValidOrInvalidStyle(value: boolean): any {
        if ([null, undefined].includes(value)) {
            return {
                'fa-circle-check': true,
            };
        }

        return {
            valid: value,
            pristine: !this.isSubmitted && value,
            'fa-circle-check': !this.isSubmitted || value,
            'fa-circle-xmark': this.isSubmitted && !value,
        };
    }

    public ngOnInit(): void {}

    public ngOnChanges({ value }: SimpleChanges): void {
        if (!value?.currentValue) {
            return;
        }

        this._setValidators(value.currentValue);

        this._valid.emit(this._isValid());
    }
}
