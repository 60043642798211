<div class="text-center flex h-screen justify-content-center align-items-center ">
    <div>
        <img alt="Error 404" src="assets/images/404-error.svg" class="w-10 xl:w-28rem"/>
        <div class="my-4 xl:my-2">
            <div class="grid justify-content-center">
                <div class="xl:col-8">
                    <h2 class="font-bold text-2xl mt-3 mb-0"> Oops! We Couldn't Find That Page 🕵️‍♂️ </h2>
                </div>
                <div class="text-xl px-3 col-11 xl:col-6">
                    <p>The page you're searching for appears to be hiding. But no worries! If you need any help, please <a href="/" class="underline">visit FAQs.</a></p>
                </div>
            </div>
        </div>
        
        <button (click)="onReturnHome()" pButton pRipple label="Back to LinovHR" class="w-5 xl:w-2"></button>
    </div>
</div>
