import { NgModule } from '@angular/core';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { LinovInfiniteScrollDirective } from './infinite-scroll.directive';

@NgModule({
    imports: [],
    declarations: [
        OnlyNumberDirective,
        AutocompleteOffDirective,
        LinovInfiniteScrollDirective,
    ],
    providers: [],
    exports: [
        OnlyNumberDirective,
        AutocompleteOffDirective,
        LinovInfiniteScrollDirective,
    ],
})
export class DirectiveModule {}
