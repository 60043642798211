import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardEssComponent } from './dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule } from '@angular/forms';
import { SharedComponentModule } from '../shared-component/shared-component.module';
import { TabViewModule } from 'primeng/tabview';
@NgModule({
    declarations: [DashboardEssComponent],
    imports: [
        CommonModule,
        NgApexchartsModule,
        FormsModule,
        SharedComponentModule,
        TabViewModule,
    ],
})
export class DashboardEssModule {}
