<div class="card grid min-h-screen p-0 m-0">
    <div class="lg:col-6 hide-mobile p-0 m-0 login-bg-left">
        <div class="grid px-4 py-4">
            <div class="col-12 center-label">
                <img
                    src="assets/logo/LinovHR.svg"
                    width="85px"
                    height="16.86px"
                />
            </div>
        </div>

        <div class="grid" style="margin-top: 4.5rem">
            <div class="col-12">
                <p-carousel
                    [value]="images"
                    [autoplayInterval]="5000"
                    [circular]="true"
                    orientation="none"
                    [numVisible]="1"
                    [numScroll]="1"
                    [responsiveOptions]="responsiveOptions"
                >
                    <ng-template let-item pTemplate="item">
                        <div class="text-center">
                            <img
                                src="{{ item.itemImageSrc }}"
                                [alt]="item.title"
                                width="515px"
                                height="436px"
                            />
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6 m-0 p-0">
        <form
            (ngSubmit)="
                showCompany ? onSendResetPassword() : handleCheckEmail()
            "
            *ngIf="!isSuccesSend"
        >
            <div class="container">
                <div class="login-box p-2">
                    <div class="p-fluid mt-4" [formGroup]="formReady">
                        <span class="p-float-label mb-4 text-left p-title">
                            Forgot Password
                        </span>
                        <p class="p-fload-label mb-3 text-left">
                            Enter your email address and we’ll send an email
                            with a link to reset your password.
                        </p>

                        <div class="field">
                            <label
                                for="_email"
                                [ngClass]="{
                                    'ng-dirty ng-invalid':
                                        (formReady.get('email').touched ||
                                            formReady.get('email').dirty) &&
                                        !formReady.get('email').valid
                                }"
                                >Email Address</label
                            >
                            <div>
                                <input
                                    pInputText
                                    id="_email"
                                    [ngClass]="{
                                        'ng-dirty ng-invalid':
                                            (formReady.get('email').touched ||
                                                formReady.get('email').dirty) &&
                                            !formReady.get('email').valid
                                    }"
                                    formControlName="email"
                                    type="text"
                                    placeholder="Ex: example@company.com"
                                    class="login-input"
                                    [readonly]="showCompany"
                                />
                                <app-error-field-helper
                                    [formControlKey]="formReady.get('email')"
                                >
                                </app-error-field-helper>
                            </div>
                        </div>

                        <div class="field" *ngIf="showCompany">
                            <label
                                for="tenantCode"
                                [ngClass]="{
                                    'ng-dirty ng-invalid':
                                        (formReady.get('tenantCode').touched ||
                                            formReady.get('tenantCode')
                                                .dirty) &&
                                        !formReady.get('tenantCode').valid
                                }"
                                >Company</label
                            >
                            <div>
                                <app-lov
                                    [filter]="true"
                                    (selected)="handleSelectTenant($event)"
                                    label="{{ 'select' | translate }}  {{
                                        'company' | translate
                                    }}"
                                    [customOption]="optTenants"
                                    [chaining]="true"
                                    optLabel="currentCompany"
                                    styleClass="w-full"
                                    [ngClass]="{
                                        'ng-dirty ng-invalid':
                                            (formReady.get('tenantCode')
                                                .touched ||
                                                formReady.get('tenantCode')
                                                    .dirty) &&
                                            !formReady.get('tenantCode').valid
                                    }"
                                ></app-lov>
                                <app-error-field-helper
                                    [formControlKey]="
                                        formReady.get('tenantCode')
                                    "
                                >
                                </app-error-field-helper>
                            </div>
                        </div>

                        <span class="p-float-label mt-3">
                            <button
                                pButton
                                pRipple
                                label="{{
                                    showCompany ? 'Send Reset Password' : 'Next'
                                }}"
                                [loading]="loading"
                            ></button>

                            <span
                                (click)="onBack()"
                                class="p-float-label text-center primary-color mt-4"
                                style="cursor: pointer"
                            >
                                Back To Sign In
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div class="container" *ngIf="isSuccesSend">
            <div class="flex flex-column align-items-center">
                <img
                    class="w-6rem"
                    src="assets/icon/icon-approved-blue.svg"
                    alt=""
                />
                <p class="p-float-label mb-2 text-left p-title mt-3">
                    Check Your Email
                </p>
                <p class="p-fload-label mb-2 text-center">
                    We have sent a password recover link to your email
                </p>

                <span class="flex flex-column mt-3 w-full" style="gap: 20px">
                    <button
                        (click)="goToMail()"
                        class="w-full"
                        pButton
                        pRipple
                        label="Open Email"
                    ></button>

                    <button
                        (click)="onOk()"
                        class="w-full"
                        style="
                            background-color: transparent;
                            color: #0e8ec5;
                            border: 1px solid #0e8ec5;
                        "
                        pButton
                        pRipple
                        label="Ok"
                    ></button>
                </span>
            </div>
        </div>
    </div>
</div>
<flash-message-error></flash-message-error>
<flash-message-success></flash-message-success>
