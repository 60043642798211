import { Injectable } from "@angular/core";
import { ApiService } from "src/app/core/service/api.service";
import { HelperService } from "src/app/core/service/helper-service";

@Injectable({
  providedIn: "root",
})
export class ActivityMasterService {
  constructor(private api: ApiService, private help: HelperService) {}
  getCompany() {
    return this.api.get("lovs/companies?isActive=true");
  }

  get(id) {
    return this.api.get("organization-levels/" + id);
  }
  add(body) {
    return this.api.post("organization-levels", body);
  }
  edit(body) {
    return this.api.put("organization-levels", body);
  }
  delete(id) {
    return this.api.delete("organization-levels/" + id);
  }
  deleteBatch(body) {
    return this.api.deleteAll("organization-levels", body);
  }
  getMaster(id) {
    return this.api.get("organizations/" + id);
  }
  addMaster(body) {
    return this.api.post("organizations", body);
  }
  editMaster(body) {
    return this.api.put("organizations", body);
  }
  deleteMaster(id) {
    return this.api.delete("organizations/" + id);
  }
  deleteBatchMaster(body) {
    return this.api.deleteAll("organizations", body);
  }
  getGroup(id) {
    return this.api.get("organization-groups/" + id);
  }
  addGroup(body) {
    return this.api.post("organization-groups", body);
  }
  editGroup(body) {
    return this.api.put("organization-groups", body);
  }
  editGroupOrganization(body) {
    return this.api.put("organization-group-organizations", body);
  }
  deleteGroup(id) {
    return this.api.delete("organization-groups/" + id);
  }
  deleteGroupOrganization(body) {
    return this.api.put("organizations/remove-group", body);
  }
  deleteBatchGroup(body) {
    return this.api.deleteAll("organization-groups", body);
  }
}
