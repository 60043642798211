import { Injectable } from '@angular/core';
import { ApiService } from '@core/service/api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProcessQueueMonitoringService {
    constructor(private readonly _api: ApiService) {}

    public download(id: string): Observable<any> {
        return this._api.getFileByte(
            `monitoring-process/download/${id}`,
            null,
            true
        );
    }

    public rollback(id: string): Observable<any> {
        return this._api.post(`monitoring-process/retry/${id}`, null, true);
    }

    public getProcessMonitoringPopUp(): Observable<any> {
        return this._api.get(`monitoring-process?pageSize=4`, null);
    }
}
