export const environment = {
    production: true,
    apiUrl: 'https://api-admin-hcms.linovhr.com/api/v1/',
    apiUrlCv: 'https://api-dev-airecruitment.linovhr.com/api/v1/LinovAIRecruitment/',
    apiUrlEss: 'https://api-ess-hcms.linovhr.com/api/v1/',
    isSSO: false,
    linovWordpress: 'https://www.upahku.id/login/',
    webSocket: 'https://api-admin-hcms.linovhr.com/socket',
    webSocketEss: 'https://api-ess-hcms.linovhr.com/api/v1/mobile/socket',
    urlJobportal: 'https://jobportal2.linovhr.com/',
    apiUrlAnalytics: 'https://api-analytic.linovhr.com/api/v1/'
};
