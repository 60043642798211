import { Title } from '@angular/platform-browser';
import { UserService } from '@workbench/user-2/user.service';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxService } from '@core/service/message-box.service';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import {
    FormGroup,
    Validators,
    FormBuilder,
    ValidatorFn,
    AbstractControl,
} from '@angular/forms';

@Component({
    selector: 'app-reset-password',
    styleUrls: ['reset-password.component.scss'],
    templateUrl: 'reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
    private _token: string = this._activatedRoute.snapshot.queryParams.token;

    public data!: any;
    public isFail!: boolean | undefined;
    public isError!: boolean | undefined;
    public loading!: boolean | undefined;
    public isSuccess!: boolean | undefined;
    public formGroup!: FormGroup<any> | undefined;
    public submitted!: boolean | undefined;
    public resendLink!: boolean | undefined;
    public isSubmitted!: boolean | undefined;
    public useOldPassword!: boolean | undefined;
    public useUsername!: boolean | undefined;
    public isValidPassword!: boolean | undefined;
    public isPasswordNotMatch!: boolean | undefined;

    public loadResetPassword: boolean = false;

    public get password(): AbstractControl<any, any> {
        return this.formGroup.get('password');
    }

    public get confirmPassword(): AbstractControl<any, any> {
        return this.formGroup.get('confirmPassword');
    }

    public get isPasswordError(): boolean {
        return this.isFail && this.submitted;
    }

    constructor(
        private readonly _title: Title,
        private readonly _formBuilder: FormBuilder,
        private readonly _userService: UserService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _changeDetectorRef: ChangeDetectorRef
    ) {}

    private _setupForm(): void {
        const ctrl: any = this._formBuilder.control.bind(this);

        this.formGroup = this._formBuilder.group<any>(
            {
                password: ctrl(null, [Validators.required, Validators.min(8)]),
                confirmPassword: ctrl(null, [Validators.required]),
            },
            {
                validators: this._matchValidator('password', 'confirmPassword'),
            }
        );
    }

    private _verifyEmail(): void {
        this.loading = true;

        this._userService.verifyResetToken(this._token).subscribe({
            next: (res: any): void => {
                this.data = res.data;

                this._setupForm();
            },
            error: (): void => {
                this.isError = true;
                this.loading = false;
            },
        });
    }

    private _matchValidator(
        controlName: string,
        matchingControlName: string
    ): ValidatorFn {
        return (abstractControl: AbstractControl) => {
            const control: any = abstractControl.get(controlName);
            const matchingControl: any =
                abstractControl.get(matchingControlName);

            if (
                matchingControl!.errors &&
                !matchingControl!.errors?.['confirmedValidator']
            ) {
                return null;
            }

            if (control!.value !== matchingControl!.value) {
                const error = {
                    confirmedValidator: 'Password confirmation does not match',
                };

                matchingControl!.setErrors(error);

                return error;
            } else {
                matchingControl!.setErrors(null);

                return null;
            }
        };
    }

    public handleResetPassword(): void {
        this.loadResetPassword = true;

        if (this.formGroup.invalid) {
            this.useOldPassword = false;
            this.useUsername = false;
            this.loadResetPassword = false;

            this.formGroup.markAllAsTouched();

            return;
        }

        this.submitted = true;

        this.isFail = this.submitted && !this.isValidPassword;
        this.isSubmitted = true;

        if (this.isFail) {
            this.useOldPassword = false;
            this.useUsername = false;
            this.loadResetPassword = false;

            return;
        }

        this._userService
            .resetPassword({
                token: this._token,
                password: this.formGroup.get('password').value,
            })
            .subscribe({
                next: (): void => {
                    this.isSuccess = true;
                    this.loadResetPassword = false;

                    setTimeout((): void => {
                        window.location.href = `${window.origin}/login`;
                    }, 2500);
                },
                error: (res: any): void => {
                    this.loadResetPassword = false;

                    const message: string =
                        JSON.parse(res)?.errors.reason.join('');

                    this._messageBoxService.showError(message);

                    if (message.includes('old password')) {
                        this.useOldPassword = true;
                    }
                    if (message.includes('contain the username')) {
                        this.useUsername = true;
                    }
                },
            });
    }

    public handleValidPasswordInfo(value: boolean): void {
        this.isValidPassword = value;

        if (this.isValidPassword) {
            this.isFail = false;
        }

        this._changeDetectorRef.detectChanges();
    }

    public handleChangeInputPassword(): void {
        const passwordLength: number = this.password.value?.length;
        const confirmPasswordLength: number =
            this.confirmPassword.value?.length;

        this.submitted = false;
        this.isPasswordNotMatch =
            this.isSubmitted &&
            (passwordLength !== confirmPasswordLength ||
                this.confirmPassword.hasError('confirmedValidator'));
    }

    public handleResendVerificationToken(): void {
        this._userService.resendVerificationToken(this._token).subscribe({
            next: (): void => {
                this.resendLink = true;
            },
            error: (): void => {
                this._messageBoxService.showError(
                    'We encountered an issue while trying to resend the verification link. Please try resending the link after some time',
                    'Verification Link Resend Failed'
                );
            },
        });
    }

    public ngOnInit(): void {
        this._title.setTitle('Reset Password | LinovHR');

        this._verifyEmail();
    }
}
