import { AppConstant } from '@config/app.config';
import { AuthenticationService } from 'src/app/core/service/authentication.service';

import { Router, NavigationEnd } from '@angular/router';

import { Input, OnInit, Component, OnDestroy } from '@angular/core';

import { map, filter } from 'rxjs/operators';

import menu from './menu';

@Component({
    selector: 'app-submenu-component',
    templateUrl: './app.submenu.component.html',
    styles: [
        `
            :host ::ng-deep {
                .p-menubar {
                    padding: 0 1rem !important;
                }
            }
        `,
    ],
})
export class AppSubMenuComponent implements OnInit, OnDestroy {
    @Input()
    public items;

    private sub = this.route.events
        .pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event) => event as NavigationEnd)
        )
        .subscribe((event) => {
            let currentModule = event.url.split('/')[2];

            if (currentModule) {
                this.changeSubMenu(currentModule);
            } else {
                this.items = [];
            }
        });

    activeUrl;

    checked: boolean = false;
    hardCodedMenu = menu;

    constructor(private route: Router, public auth: AuthenticationService) {}

    private _setupSubMenus(): void {
        this.hardCodedMenu = [];
        this.items = [];

        if (this.auth.getLocalStorage(AppConstant.menuAdmin)) {
            this.hardCodedMenu = this.auth.getLocalStorage(
                AppConstant.menuAdmin
            );
        }
    }

    public ngOnInit(): void {
        this._setupSubMenus();

        let currentModule = this.route.url.split('/')[2];

        if (currentModule) {
            this.changeSubMenu(currentModule);
        } else {
            this.items = [];
        }
    }

    public changeSubMenu(currentModule): void {
        this.hardCodedMenu.forEach((element) => {
            let label = element.items[0]?.label.toLocaleLowerCase();

            if (label == 'dashboard analytics') {
                label = 'analytics';
            }

            if (label == 'time & attendance') {
                label = 'time';
            }

            if (label == 'training') {
                label = 'lnd';
            }

            if (label == 'organization design') {
                label = 'organization';
            }

            if (label == 'log history') {
                label = 'log-history';
            }
            if (label == 'custom report') {
                label = 'custom-reports';
            }

            if (label == currentModule.toLocaleLowerCase()) {
                // @ts-ignore
                this.items = element.items[0]?.subMenu;

                this.resetStyleclass(0, this.items);
                this.recursiveMenu(this.items);
            }
        });
    }

    public onClickMenu(): void {
        this.recursiveMenu(this.items, true);
    }

    public async recursiveMenu(
        items,
        triggeredByClick: boolean = false
    ): Promise<void> {
        this.activeUrl = this.route.url;

        items.forEach((element) => {
            if (element.items?.length > 0) {
                this.recursiveMenu(element.items, triggeredByClick);

                if (
                    element.items.some((child) => child.styleClass === 'active')
                ) {
                    element.styleClass = 'active';
                }
            } else if (element.routerLink?.[0] === this.activeUrl) {
                this.activateMenu(element);
            }
        });
    }

    private activateMenu(element): void {
        this.resetStyleclass(0, this.items);
        element.styleClass = 'active';
    }

    public resetStyleclass(idx = 0, arrData): void {
        for (let i = idx; i < arrData.length; i++) {
            arrData[i].styleClass = '';

            if (arrData[i].items) {
                this.resetStyleclass(0, arrData[i].items);
            }
        }
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
